import React from 'react';
import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';
import { Grid } from '@mui/material';
import axios from 'axios';
import { MAIN_HOST } from 'common/AppConstant';
import { decreaseFetchingQueue } from 'app/state/reducer';
import store from 'app/store';

export default function UserAdmin() {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <CustomizedMaterialTable
          title="Users"
          options={{
            search: true,
            pageSizeOptions: [10, 20, 50],
            pageSize: 10,
            paging: true,
            actionsColumnIndex: -1,
          }}
          columns={[
            { title: 'First', field: 'firstName' },
            { title: 'Last', field: 'lastName' },
            { title: 'Email', field: 'email' },
            { title: 'Admin', field: 'sysAdmin' },
          ]}
          data={(query) =>
            new Promise((resolve) => {
              axios
                .post(`${MAIN_HOST}/user/search`, {
                  page: query.page,
                  size: query.pageSize,
                  searchTerm: query.search,
                })
                .then((m) => {
                  const { data } = m;
                  resolve({
                    data: data.content,
                    page: data.number,
                    size: data.size,
                    totalCount: data.totalElements,
                  });
                })
                .catch(() => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                  store.dispatch(decreaseFetchingQueue());
                });
            })
          }
          actions={
            [
              //   {
              //     position: 'row',
              //     icon: () => <EditIcon />,
              //     tooltip: 'Edit',
              //     onClick: (_, rowData) => {
              //       handleNetworkOpen({ ...rowData });
              //     }
              //   },
              //   {
              //     position: 'row',
              //     icon: () => <DeleteIcon />,
              //     tooltip: 'Delete',
              //     onClick: (_, rowData) => deleteByNetworkId(rowData.code)
              //   },
              //   {
              //     icon: () => <RefreshIcon />,
              //     tooltip: 'Refresh Data',
              //     onClick: () => {
              //       refreshNetworks();
              //     },
              //     isFreeAction: true
              //   },
              //   {
              //     icon: () => <AddIcon />,
              //     tooltip: 'Add',
              //     isFreeAction: true,
              //     onClick: () => {
              //       handleNetworkOpen(null);
              //     }
              //   }
            ]
          }
        />
      </Grid>
    </Grid>
  );
}
