import axios from 'axios';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const search = createAsyncThunk('search', async (data) => {
  const response = await axios.post(`${MAIN_HOST}/search/state`, data);

  return response.data;
});

export const searchWithFilter = createAsyncThunk(
  'searchWithFilter',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/search/state`, data);

    return response.data;
  },
);

export const newSearchWithFilter = createAsyncThunk(
  'newSearchWithFilter',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/search/state`, data);

    return response.data;
  },
);

export const searchToDownload = createAsyncThunk(
  'saveRequiredFile',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/required-file`, data);
    return response.data;
  },
);

export const searchNetworks = createAsyncThunk('searchNetworks', async () => {
  const response = await axios.get(`${MAIN_HOST}/lookup/states`);

  return response.data;
});

// not a thunk
export const callSuggestionApi = async (data) => {
  const response = await axios.post(`${MAIN_HOST}/search/suggestion`, data);
  return response.data;
};

export const getSuggestion = createAsyncThunk(
  'search/suggestion',
  callSuggestionApi,
);

export const onKeywordChange = createAction('search/keywordChange');
export const clearSuggestions = createAction('search/clearSuggestion');
export const clearStatus = createAction('search/clearStatus');

export default search;
