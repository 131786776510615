import { Box, Grid } from '@mui/material';
import React from 'react';
import './detailDialog.css';
import DetailItemPaper from './detailItemPaper';

// NPI Provider
// Rate
// Code

export default function DetailDialog({ data }) {
  const { rowData } = data;
  const iterable = [
    {
      code: 'npi',
      title: 'NPI',
      value: rowData.npi,
      group: 'number',
    },
    {
      code: 'taxid',
      title: 'Tax ID',
      value: rowData.taxid,
      group: 'number',
    },
    {
      code: 'addr',
      title: 'Address',
      value: `${rowData.addr ?? ''} ${rowData.city ?? ''} ${rowData.state ?? ''} ${rowData.zip ?? ''}`,
      group: 'long-text',
    },
    {
      code: 'codecategory',
      title: 'Code Category',
      value: rowData.codecategory,
      group: 'long-text',
    },
    {
      code: 'servicecode',
      title: 'Service Code',
      value: rowData.servicecode,
      group: 'text',
    },
  ];

  return (
    <Box>
      <Grid container spacing={3} sx={{ maxHeight: '500px' }}>
        <Grid container spacing={0} item xs={12}>
          {iterable.map((item) => (item.group === 'number'
            && (
            <Grid item xs={1.6}>
              <DetailItemPaper
                data={item}

           />
            </Grid>
            )
          ))}
          {iterable.map((item) => (item.group === 'long-text'
            && (
            <Grid item xs={3.50}>
              <DetailItemPaper
                data={item}

           />
            </Grid>
            )
          ))}
          {iterable.map((item) => (item.group === 'text'
            && (
            <Grid item xs={1.80}>
              <DetailItemPaper
                data={item}

           />
            </Grid>
            )
          ))}
        </Grid>
      </Grid>
    </Box>

  );
}
