import { createSlice } from '@reduxjs/toolkit';
import jwt from 'jwt-decode';
import { COOKIE_JWT, SIGN_OUT } from 'common/AppConstant';
import * as API from './service';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    firstName: '',
    lastName: '',
    userId: null,
    displayName: '',
    errorMsg: null,
    user: null,
    requiredPasswordChange: false,
    jwtExp: null,
    permissions: [],
  },
  reducers: {
    resetJwt(state) {
      state.jwtExp = null;
      localStorage.setItem(SIGN_OUT, true);
    },
    resetUser(state) {
      state.user = null;
      state.firstName = '';
      state.lastName = '';
      state.displayName = '';
      state.permissions = [];
    },
    resetLoginErrorMsg(state) {
      state.errorMsg = null;
    },
    setErrorMsg(state, action) {
      state.errorMsg = action.payload;
    },
    updateJwt(state, action) {
      localStorage.setItem(COOKIE_JWT, action.payload);
      localStorage.setItem(SIGN_OUT, false);
      state.displayName = '';
      authSlice.caseReducers.resetLoginErrorMsg(state);

      if (action.payload) {
        const userInfo = jwt(action.payload);

        if (Date.now() >= userInfo.exp * 1000) {
          localStorage.clear();
          return;
        }
        state.jwtExp = userInfo.exp;

        const { firstName } = userInfo;
        const { lastName } = userInfo;
        const { userId } = userInfo;
        state.firstName = firstName;
        state.lastName = lastName;
        state.userId = userId;

        if (userInfo.firstName) {
          state.displayName += userInfo.firstName.charAt(0).toUpperCase();
        }

        if (userInfo.lastName) {
          state.displayName += userInfo.lastName.charAt(0).toUpperCase();
        }

        state.permissions = userInfo.permission;
      } else {
        state.firstName = '';
        state.lastName = '';
        state.userId = null;
        state.displayName = '';
        state.permissions = [];
      }
    },
    updatePasswordChangeStatus: (state, action) => {
      state.requiredPasswordChange = action;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(API.login.fulfilled, (state, action) => {
      authSlice.caseReducers.updateJwt(state, action);
    });
    builder.addCase(API.passwordChange.fulfilled, (state) => {
      state.requiredPasswordChange = false;
    });
    builder.addCase(API.ssoLogin.fulfilled, (state, action) => {
      authSlice.caseReducers.updateJwt(state, action);
    });
    builder.addCase(API.refreshToken.fulfilled, (state, action) => {
      authSlice.caseReducers.updateJwt(state, action);
    });
  },
});

export const { resetJwt, updateJwt, resetLoginErrorMsg, setErrorMsg,
  resetUser, updatePasswordChangeStatus } = authSlice.actions;
export default authSlice.reducer;
