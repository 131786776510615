import React, { forwardRef } from 'react';
import { Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CATEGORY, PERCENT_DIFF } from 'common/Constants';
import SortIndicator from 'common/components/SortIndicator';
import TableCellHeader from 'common/components/TableCellHeader';

const useStyles = makeStyles(() => ({
  header: {
    '& h6': {
      color: '#323d47',
      fontSize: '0.85rem',
    },
    paddingTop: '0.5rem',
  },
}));

const tableHeaderCells = [
  {
    id: 'score',
    label: 'Score',
  },
  {
    id: 'idealPercent',
    label: 'Weights',
  },
  {
    id: 'medicarePercent',
    label: '% Medicare',
  },
];

export default function TableHeader(props) {
  const styles = useStyles();
  const { order, orderBy, onRequestSort, active } = props;

  const handleSort = (property) => (event) => {
    onRequestSort(property, event);
  };

  return (
    <Grid container className={styles.header}>
      <Grid item xs={3}>
        <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
          <Box paddingLeft="12px">
            <TableCellHeader
              key={CATEGORY}
              label="Code category"
              sortDirection={orderBy === CATEGORY ? order : false}
              active={orderBy === CATEGORY && active}
              direction={orderBy === CATEGORY && active ? order : 'asc'}
              onClick={handleSort(CATEGORY)}
              IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} />)}
              variant="h6"
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" flexDirection="row">
          {tableHeaderCells.map((e) => (
            <Box
              flex={1}
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
            >
              <TableCellHeader
                key={e.id}
                label={e.label}
                sortDirection={orderBy === e.id ? order : false}
                active={orderBy === e.id && active}
                direction={orderBy === e.id && active ? order : 'asc'}
                onClick={handleSort(e.id)}
                IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} />)}
                variant="h6"
              />
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={2}>
        <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="center" alignItems="center">
          <TableCellHeader
            key={PERCENT_DIFF}
            label="Category difference"
            sortDirection={orderBy === PERCENT_DIFF ? order : false}
            active={orderBy === PERCENT_DIFF && active}
            direction={orderBy === PERCENT_DIFF && active ? order : 'asc'}
            onClick={handleSort(PERCENT_DIFF)}
            IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} />)}
            variant="h6"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
