/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert, Snackbar } from '@mui/material';
import * as Constants from 'common/Constants';
import Spinner from 'common/components/spinner';
import ConfirmDialog from 'common/components/ConfirmationDialog';
import { updateSystemErrorMsg, openLogoutDialog } from 'app/state/reducer';
import { refreshToken } from 'features/login/state/service';
import { COOKIE_JWT, SIGN_OUT, USE_APPD, USE_CLARITY } from 'common/AppConstant';
import LogoutConfirmDialog from 'features/login/components/logoutConfirmDialog';
import AppDynamicsRUM from 'AppDynamicsRUM';
import store from './app/store';
import AppRoute from './app/router/AppRoute';

const body1Style = {
  fontSize: '1rem',
  color: Constants.TEXT_BLACK,
};

const customTheme = createTheme({
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  components: {
    HtmlToImageIgnore: {},
    MuiTable: {
      styleOverrides: {
        root: {
          '& tr th:last-child': {
            textAlign: 'left !important',
            paddingLeft: '16px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: () => ({
          lineHeight: '1.2rem',
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          paddingTop: '1.5rem',
          paddingBottom: '2rem',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          margin: 2,
        },
      },
      variants: [
        {
          props: { variant: 'dashboardItemCard' },
          style: {
            paddingBottom: '1rem',
            width: '100%',
            borderRadius: '12px',
          },
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        },
      },
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            fontSize: '1.75rem',
            fontWeight: 'bold',
            color: Constants.PRIMARY_DARK,
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            color: Constants.PRIMARY_DARK,
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            fontSize: '1.75rem',
            fontWeight: 'bold',
            color: Constants.PRIMARY_DARK,
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            color: Constants.PRIMARY_DARK,
          },
        },
        {
          props: { mystyle: 'paraHeader' },
          style: {
            color: 'black',
            paddingBottom: '3px',
          },
        },
        {
          props: { mystyle: 'pageHeader' },
          style: {
            color: 'black',
            paddingBottom: '40px',
          },
        },
        {
          props: { variant: 'h6' },
          style: {
            fontSize: '1rem',
            fontWeight: 'bold',
            color: Constants.PRIMARY,
          },
        },
        {
          props: { variant: 'body1' },
          style: body1Style,
        },
        {
          props: { variant: 'cardLabel' },
          style: { ...body1Style, fontWeight: 'bold' },
        },
        {
          props: { variant: 'resultCard' },
          style: {
            fontSize: '1rem',
          },
        },
        {
          props: { mystyle: 'stepper' },
          style: {
            color: Constants.PRIMARY_DARK,
          },
        },
        {
          props: { variant: 'statsLabel' },
          style: {
            fontSize: '0.75rem',
            fontWeight: '500',
            marginLeft: '1rem',
            marginTop: '2rem',
            color: 'black',
          },
        },
      ],
      defaultProps: {
        variantMapping: {
          statsLabel: 'span',
        },
      },
    },
    MuiGrid: {
      variants: [
        {
          props: { variant: 'addPaddingBottom' },
          style: {
            paddingBottom: '0.5rem',
          },
        },
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiGrid-container .nestedTableheaders': {
            backgroundColor: theme.palette.gridHeaderBg.main,
            '& h6': {
              color: theme.palette.grey[800],
              fontSize: '1rem',
            },
          },
          '& .MuiGrid-container .nestedTablerows': {
            backgroundColor: theme.palette.gridRowBg.main,
            paddingTop: '0.5rem',
          },
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.white,
          '& .MuiInputLabel-shrink': {
            color: Constants.TEXT_BLACK,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: Constants.TEXT_BLACK,
            },
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiChip-deleteIcon': {
            color: theme.palette.chipIconBg.main,
          },
        }),
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTableSortLabel-icon': {
            color: theme.palette.sortIcon.main,
            transform: 'rotate(0deg)!important',
            opacity: 1,
            width: '2em',
            height: '2em',
            fontSize: 'unset',
            margin: 'unset',
          },
          '&.Mui-active .MuiTableSortLabel-icon': {
            color: theme.palette.sortIcon.main,
            opacity: 1,
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        startIcon: {
          marginRight: '0.125em',
        },
      },
    },
  },
  palette: {
    background: {
      default: '#F0F2F5',
      white: '#ffffff',
    },
    darkest: {
      main: Constants.PLUS_MINUS_DARK,
    },
    primary: {
      main: Constants.PRIMARY,
      dark: Constants.PRIMARY_DARK,
      light: Constants.PRIMARY_LIGHT,
    },
    secondary: {
      main: '#002E74',
      link: '#004784',
    },
    accent: {
      shade200: Constants.LIGHTER_GRAY_200,
      shade300: Constants.LIGHTER_GRAY_300,
      gray600: '#535C69',
      darkgreen: '#2e6f40',
    },
    error: {
      main: Constants.PRIMARY_RED, // need to change this to dark shade which dana has provided.
    },
    pending: {
      main: '#F1C93B',
    },
    tag: {
      main: '#C7D0DB',
    },
    chipIconBg: {
      main: '#6A7585',
    },
    sortIcon: {
      main: '#353B43',
    },
    gridRowBg: {
      main: '#EEF0F3',
    },
    gridHeaderBg: {
      main: '#C7D0DB',
    },
  },
});

export default function App() {
  const dispatch = useDispatch();

  const systemMsg = useSelector((state) => state.app.systemMsg);
  const jwtExp = useSelector((state) => state.auth.jwtExp);

  const showLogoutDialog = useSelector((state) => state.app.showLogoutDialog);

  const isFetchingQueue = useSelector(
    (state) => state.app.isFetchingQueue,
  );

  const systemMsgSeverity = useSelector(
    (state) => state.app.systemMsgSeverity,
  );

  const onCloseError = () => {
    dispatch(updateSystemErrorMsg(null));
  };

  useEffect(() => {
    const timer = setInterval(
      () => {
        const signout = localStorage.getItem(SIGN_OUT);
        if (signout !== 'true' && jwtExp) {
          const leftTime = (jwtExp * 1000) - Date.now();
          // if left time > 0 then, the token is valid
          // if the token is expired in 5 mins, refresh the token
          if (leftTime > 0 && leftTime < 300000) {
            store.dispatch(refreshToken());
          }
        }
      },
      1000,
    );
    return () => clearInterval(timer);
  });

  useEffect(() => {
    const timer = setInterval(
      () => {
        const existingJwt = localStorage.getItem(COOKIE_JWT);
        const signout = localStorage.getItem(SIGN_OUT);
        if (!existingJwt && signout === 'true') {
          store.dispatch((openLogoutDialog()));
        }
      },
      1000,
    );
    return () => clearInterval(timer);
  });

  // use Clarity
  useEffect(() => {
    if (USE_CLARITY) {
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
        t = l.createElement(r);
        t.async = 1;
        t.src = `https://www.clarity.ms/tag/${i}`;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      }(window, document, 'clarity', 'script', process.env.REACT_APP_CLARITY_TRACKING_ID));
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={customTheme}>
        <AppRoute />
        {USE_APPD && <AppDynamicsRUM />}
        {systemMsg && (
          <Snackbar
            open
            onClose={onCloseError}
            severity="error"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={15000}
          >
            <Alert severity={systemMsgSeverity} onClose={onCloseError}>
              {systemMsg}
            </Alert>
          </Snackbar>
        )}
        {isFetchingQueue > 0 && (
          <Spinner />
        )}
        <LogoutConfirmDialog open={showLogoutDialog} />
      </ThemeProvider>
      <ConfirmDialog />
    </Provider>
  );
}
