import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import NetworkDetail from './networkDetail';

function NetworkDialog(props) {
  const { onClose, open, target } = props;

  const methods = useForm({ defaultValues: target, mode: 'onSubmit' });
  const { reset } = methods;

  const [shouldDisableSaveButton, setShouldDisableSaveButton] = useState(false);

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = async (data) => {
    onClose(data, target == null);
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);

  return (
    <FormProvider {...methods}>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {target !== null ? 'Edit Network' : 'New Network'}
        </DialogTitle>
        <DialogContent dividers>
          <NetworkDetail target={target} setShouldDisableSaveButton={setShouldDisableSaveButton} />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={methods.handleSubmit(onSubmit)}
            color="primary"
            disabled={!methods.formState.isDirty || shouldDisableSaveButton}>
            Save
          </Button>
          <Button autoFocus onClick={() => handleClose(null)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}

export default NetworkDialog;
