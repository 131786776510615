import { useSelector } from 'react-redux';

export const usePermissions = () => {
  const permissions = useSelector((state) => state.auth.permissions);
  const hasPermission = (perm) => {
    if (!permissions) {
      return false;
    }
    return permissions.includes(perm);
  };

  return { hasPermission };
};

export default usePermissions;
