import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { FORMAT_NATURAL } from 'common/util/commonUtil';
import { getCensusStatistics, getCensusList } from 'features/census/state/service';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import store from 'app/store';
import { FILE_UPLOAD_PROPS } from 'common/Constants';

export default function CensusStats({ censusObj, dialogTitle }) {
  const { setValue } = useFormContext();
  const [statsObj, setStatsObj] = useState(null);
  const [showStats, setShowStats] = useState(false);
  const uploadSuccess = useSelector((state) => state.evaluation.uploadSuccessFlag);
  const uploadedCensus = useSelector((state) => state.census.uploadedCensus);

  const getCensusStatisticsDetail = (id) => {
    try {
      getCensusStatistics(id).then((data) => {
        setStatsObj(data);
        setShowStats(true);
      });
    } catch (error) {
      setShowStats(false);
      setStatsObj(null);
    }
  };

  useEffect(() => {
    if (censusObj && censusObj.id) {
      setShowStats(false);
      getCensusStatisticsDetail(censusObj.id);
    }
  }, [censusObj]);

  useEffect(() => {
    if (uploadSuccess === true && dialogTitle === FILE_UPLOAD_PROPS.census.dialogTitle) {
      store.dispatch(getCensusList());
      setValue('census', uploadedCensus, { shouldDirty: true });
      getCensusStatisticsDetail(uploadedCensus?.id);
    }
  }, [uploadSuccess]);

  return (
    showStats && (
      <Grid container direction="column">
        <Grid item>
          <Typography variant="statsLabel">
            Total MSAs:
          </Typography>
          <Typography variant="caption" marginLeft="0.5rem">
            {statsObj.noOfMSA}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="statsLabel">
            Total Population:
          </Typography>
          <Typography variant="caption" marginLeft="0.5rem">
            {FORMAT_NATURAL(statsObj.totalPopulation)}
          </Typography>
        </Grid>
      </Grid>
    )
  );
}
