import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePermissions } from 'features/common/hooks/usePermissions';
import RoutePath from './RoutePath';

export default function PermissionRoute({ children, permission }) {
  const { hasPermission } = usePermissions();

  if (!hasPermission(permission)) {
    return <Navigate to={RoutePath.app.error.path} replace />;
  }

  return children;
}
