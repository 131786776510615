import React from 'react';
import { Grid } from '@mui/material';
import NetworkSummaryCard from './NetworkSummaryCard';
import { SummaryWrapperBox } from '../../evaluationResult';

export default function NetworkSummaries({ summaries }) {
  return (
    <Grid item xs={12}>
      <SummaryWrapperBox>
        {summaries?.map((summary, idx) => (
          <NetworkSummaryCard
            index={idx}
            key={summary.network?.label}
            summary={summary} />
        ))}
      </SummaryWrapperBox>
    </Grid>
  );
}
