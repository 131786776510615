import React from 'react';
import { useTheme } from '@mui/material/styles';
import { POPULATION_NETWORK_RESULT, PLAN_BY_STATE_MAX_MSA_RATING, PLAN_BY_STATE_MIN_MSA_RATING } from 'common/Constants';
import { Grid } from '@mui/material';

export default function HorizontalRatingBar({ rating, height, scale, style, source }) {
  const theme = useTheme();
  return (
    <Grid container>
      {((source === POPULATION_NETWORK_RESULT) || (source === PLAN_BY_STATE_MAX_MSA_RATING))
                 && (
                 <>
                   <span style={{
                     display: 'inline-block',
                     height: `${height || '0.6rem'}`,
                     width: `${rating * (scale || 0.6)}rem`,
                     backgroundColor: (source === POPULATION_NETWORK_RESULT)
                       ? theme.palette.accent.gray600
                       : theme.palette.accent.darkgreen,
                     marginRight: '5px',
                     marginTop: '6px',
                     ...style,
                   }} />
                   <span>
                     {rating}
                   </span>
                 </>
                 )}
      { (source === PLAN_BY_STATE_MIN_MSA_RATING)
                 && (
                 <>
                   { rating }
                   <span style={{
                     display: 'inline-block',
                     height: `${height || '0.6rem'}`,
                     width: `${rating * (scale || 0.6)}rem`,
                     backgroundColor: theme.palette.accent.gray600,
                     marginLeft: '5px',
                     marginTop: '6px',
                     ...style,
                   }} />
                 </>
                 )}
    </Grid>
  );
}
