import React from 'react';
import {
  Grid,
  TextField,
} from '@mui/material';
import { THIS_FIELD_IS_REQUIRED } from 'common/ErrorConstants';
import { Controller } from 'react-hook-form';
import InputHeader from './InputHeader';

export default function EvaluationName(props) {
  const { control, sizeXS } = props;
  const label = 'Study Name';
  return (
    <>
      <InputHeader title="Name your study" />
      <Grid container item xs={sizeXS || 6}>
        <Controller
          name="name"
          rules={{
            required: THIS_FIELD_IS_REQUIRED,
            validate: (value) => (value.trim().length > 0 || THIS_FIELD_IS_REQUIRED),
          }}
          control={control}
          defaultValue=""
          render={({
            field,
            fieldState: { error },
          }) => (
            <TextField
              id="input_name"
              label={label}
              {...field}
              required
              inputProps={{ maxLength: 244 }}
              fullWidth
              error={error}
              helperText={error ? error.message : null}
            />
          )
          }
        />
      </Grid>
    </>
  );
}
