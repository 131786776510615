import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useBlocker from './useBlocker';

export default function useCallbackPrompt(blockNavigation) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setLastLocation(null);
  }, []);

  const handleBlockNavigation = useCallback((nextLocation) => {
    if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
      setShowPrompt(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  }, [confirmedNavigation, location]);

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location?.pathname);
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockNavigation, blockNavigation);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
