import React from 'react';
import { Grid, Box, Typography, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FORMAT_PERCENTAGE, FORMAT_RATE } from 'common/util/commonUtil';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  procedure: {
    paddingLeft: '40px',
  },
}));

export default function DetailRow({ data }) {
  const styles = useStyles();
  const evaluation = useSelector((state) => state.evaluation.evaluation);

  return (
    <Grid container className="nestedTablerows">
      <Grid item xs={3}>
        <Stack className={styles.procedure}>
          <Typography sx={{ fontWeight: 500 }}>{data.codeName}</Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography
              sx={{ fontWeight: 300, fontSize: '0.8rem' }}
            >
              {`${data.billingCodeType} ${data.billingCode}`}
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          height="100%"
        >
          <Box flex={1} />
          <Box flex={1}>
            <Typography variant="body">{FORMAT_PERCENTAGE(data.percentPay)}</Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body">{FORMAT_PERCENTAGE(data.medicarePercent, 0, '')}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={evaluation.evalType === 'MARKET' ? 1 : 2}>
        <Box display="flex" flexDirection="row" alignItems="center" height={1} justifyContent="center">
          <Typography variant="body">{FORMAT_RATE(data.mainNegotiatedRate)}</Typography>
        </Box>
      </Grid>
      {evaluation.evalType === 'MARKET' && (
        <Grid item xs={1}>
          <Box display="flex" flexDirection="row" alignItems="center" height={1} justifyContent="center">
            <Typography variant="body">{data.mainNetworkNpiCount}</Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={evaluation.evalType === 'MARKET' ? 1 : 2}>
        <Box display="flex" flexDirection="row" alignItems="center" height={1} justifyContent="center">
          <Typography variant="body">{FORMAT_RATE(data.targetNegotiatedRate)}</Typography>
        </Box>
      </Grid>
      {evaluation.evalType === 'MARKET' && (
        <Grid item xs={1}>
          <Box display="flex" flexDirection="row" alignItems="center" height={1} justifyContent="center">
            <Typography variant="body">{data.targetNetworkNpiCount}</Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={2}>
        <Box display="flex" flexDirection="row" alignItems="center" height={1} justifyContent="center">
          <Typography variant="body">
            {
              FORMAT_PERCENTAGE((data.targetNegotiatedRate - data.mainNegotiatedRate)
              / data.mainNegotiatedRate)
            }
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
