import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const getProviders = createAsyncThunk('provider/all', async () => {
  const response = await axios.get(`${MAIN_HOST}/provider/all`);
  return response.data;
});

export const getNetworks = createAsyncThunk('lookup/networks', async () => {
  const response = await axios.get(`${MAIN_HOST}/state-network`);
  return response.data;
});

export const getStates = createAsyncThunk('lookup/states', async () => {
  const response = await axios.get(`${MAIN_HOST}/lookup/states`);
  return response.data.map((state) => ({
    code: state.code,
    label: state.label,
  }));
});

export const saveProvider = createAsyncThunk(
  'provider',
  async (data, { dispatch }) => {
    const response = await axios.post(`${MAIN_HOST}/provider`, data);
    dispatch(getProviders());

    return response.data;
  },
);

export const updateNetwork = createAsyncThunk(
  'provider/update-network',
  async (data, { dispatch }) => {
    const { tableData, ...rest } = data;
    const rqData = {
      ...rest,
      states: rest.states ? rest.states.map((state) => state.code || state) : [],
    };
    const response = await axios.put(`${MAIN_HOST}/state-network`, rqData);
    dispatch(getNetworks());

    return response.data;
  },
);

export const saveNetwork = createAsyncThunk(
  'provider/network',
  async (data, { dispatch }) => {
    const { tableData, ...rest } = data;
    const rqData = {
      ...rest,
      states: rest.states ? rest.states.map((state) => state.code || state) : [],
    };
    const response = await axios.post(`${MAIN_HOST}/state-network`, rqData);
    dispatch(getNetworks());

    return response.data;
  },
);

export const deleteProvider = createAsyncThunk(
  'delete/provider',
  async (id, { dispatch }) => {
    const response = await axios.delete(`${MAIN_HOST}/provider/id/${id}`);
    dispatch(getProviders());

    return response.data;
  },
);

export const deleteNetwork = createAsyncThunk(
  'delete/network',
  async (id, { dispatch }) => {
    const response = await axios.delete(`${MAIN_HOST}/state-network/${id}`);
    dispatch(getNetworks());

    return response.data;
  },
);

export const getAllNetworks = createAsyncThunk(
  'common_searchAllNetworks',
  async () => {
    const response = await axios.get(`${MAIN_HOST}/lookup/networks`);

    return response.data;
  },
);

export const getAllMsa = createAsyncThunk(
  'common_searchAllMsa',
  async () => {
    const response = await axios.get(`${MAIN_HOST}/lookup/msa`);

    return response.data;
  },
);

export const trackVisit = createAsyncThunk(
  'trackVisit',
  async (type) => {
    const response = await axios.post(`${MAIN_HOST}/track/main/${type}`);
    return response.data;
  },
);

export const getAllPlanType = createAsyncThunk(
  'search/plantype/all',
  async () => {
    const response = await axios.get(`${MAIN_HOST}/search/plantype/all`);
    return response.data;
  },
);

export default saveProvider;
