import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const getMenuCounts = createAsyncThunk('menu/counts', async (data) => {
  const response = await axios.get(`${MAIN_HOST}/menu/counts?fetchEvaluationCount=${data.fetchEvaluationCount}&fetchSearchCount=${data.fetchSearchCount}&fetchWeightsCount=${data.fetchWeightsCount}`);
  return response.data;
});

export default getMenuCounts;
