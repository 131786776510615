/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import * as Constants from 'common/Constants';
import { Grid, Typography } from '@mui/material';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: Constants.PRIMARY_DARK,
};

const acceptStyle = {
  borderColor: Constants.PRIMARY,
};

const rejectStyle = {
  borderColor: Constants.PRIMARY_WARNING,
};

function StyledDropzone({
  maxFileSize,
  onChange,
  fileSizeValidator,
  error,
  ...fields
}) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    ...fields, accept: { 'text/*': ['.csv'] }, maxFiles: 1, maxSize: maxFileSize * 1024 * 1024,
  });

  const files = acceptedFiles.map((file) => (
    <Typography key={file.path} variant="body1" mystyle="download">
      {` ${file.path} - ${file.size} bytes`}
    </Typography>
  ));

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(acceptedFiles);
    }
  }, [acceptedFiles, onChange]);

  let detailedMessage = '';
  if (error) {
    detailedMessage = error.message;
  } else if (files && files.length > 0) {
    detailedMessage = files;
  } else {
    detailedMessage = <>&nbsp;Currently no file here. Please upload one.</>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps({ onChange })} />
          {files && files.length > 0
            ? <p>Drag and drop, or click to replace the existing file</p>
            : <p>Drag and drop some files here, or click to select files</p>}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h6">
              File *
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color={error ? Constants.PRIMARY_WARNING : null}>
              {detailedMessage}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StyledDropzone;
