import React, { useMemo } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import RoutePath from 'app/router/RoutePath';
import Settings from './setting';
import UserAdmin from './user';

export default function Admin() {
  const location = useLocation();

  const adminContent = useMemo(() => {
    const pathEnd = `${location.pathname.split('/').slice(-1)}`;

    switch (pathEnd) {
      case RoutePath.app.admin.networkMapping.subpath:
        return <Settings />;
      case RoutePath.app.admin.user.subpath:
        return <UserAdmin />;
      default:
        return <Settings />;
    }
  }, [location.pathname]);

  return (
    <>
      <Tabs>
        <Tab
          label="Network Mappings"
          component={Link}
          to="/app/admin/network-mapping"
        />
        <Tab label="User Management" component={Link} to="/app/admin/user" />
      </Tabs>
      {adminContent}
    </>
  );
}
