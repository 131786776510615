import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Grid,
  Autocomplete,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

import { getStates } from 'features/common/state/service';
import store from 'app/store';

const useStyles = makeStyles(() => ({
  section: {
    padding: '1rem',
  },
}));

function NetworkDetail({ target, setShouldDisableSaveButton }) {
  const stateOptions = useSelector((state) => state.provider.states);

  const { control, watch } = useFormContext();
  const styles = useStyles();

  const watchAllState = watch('allState');
  const watchState = watch('states');

  useEffect(() => {
    store.dispatch(getStates());
  }, []);

  useEffect(() => {
    setShouldDisableSaveButton(
      (watchAllState === false || watchAllState === undefined)
      && (watchState === undefined || watchState.length === 0),
    );
  }, [watchAllState, watchState]);

  return (
    <Grid container>
      <Grid item xs={6} className={styles.section}>
        <Controller
          name="code"
          control={control}
          rules={{
            required: 'Code cannot be empty.',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              disabled={target !== null}
              fullWidth
              required
              error={error}
              helperText={error ? error.message : null}
              id="input-code"
              label="Network Code"
            />
          )}
        />
      </Grid>
      <Grid item xs={6} className={styles.section}>
        <Controller
          name="label"
          control={control}
          rules={{
            required: 'Label cannot be empty.',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              required
              error={error}
              helperText={error ? error.message : null}
              id="input-label"
              label="Network Label"
            />
          )}
        />
      </Grid>
      <Grid item xs={10} className={styles.section}>
        <Controller
          name="states"
          control={control}
          render={({ field }) => (
            <Autocomplete
              id="state"
              {...field}
              disabled={watchAllState}
              fullWidth
              multiple
              onChange={(_, data) => field.onChange(data)}
              options={stateOptions}
              filterSelectedOptions
              isOptionEqualToValue={(opt, val) => opt.label === val.label}
              renderInput={(inProps) => (
                <TextField {...inProps} label="States" />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={2} className={styles.section}>
        <Controller
          name="allState"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
              control={<Switch />}
              label="All"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default NetworkDetail;
