import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'app/router/RoutePath';
import store from 'app/store';
import {
  COOKIE_JWT, MULTIPLAN_OKTA_SSO,
} from 'common/AppConstant';
import Spinner from 'common/components/spinner';
import { getCurrentUser } from './state/service';

export default function SsoRedirection() {
  const navigate = useNavigate();

  useEffect(() => {
    const savedJwt = localStorage.getItem(COOKIE_JWT);

    if (savedJwt && savedJwt !== 'null') {
      store.dispatch(getCurrentUser());
      navigate(RoutePath.app.home.path);
    } else {
      window.location.href = MULTIPLAN_OKTA_SSO;
    }
  }, []);

  return (
    <Spinner />
  );
}
