import { createSlice } from '@reduxjs/toolkit';
import * as API from './service';

const initialResultDetails = {
  evaluationResultDetails: {},
  populationResultDto: {},
  studyResultDto: {},
};

export const evaluationSlice = createSlice({
  name: 'Evaluation',
  initialState: {
    evaluations: [],
    evaluation: {},
    currentStatus: null,
    results: [],
    npiLinkages: [],
    evaType: null,
    linkageOriginalInput: [],
    msaSuggestions: [],
    detailChangedTrigger: false,
    evaluationResultDetailRatio: [],
    customizedWeights: [],
    uploadSuccessFlag: false,
    shouldRefresh: 0,
    allRollups: {},
    resultDetails: initialResultDetails,
    billingClassRatio: {},
    accessDeniedFlag: false,
    isResultLoading: false,
    prevVisitState: null,
  },
  reducers: {
    resetEvaluation(state) {
      state.evaluation = {};
      state.currentStatus = null;
      state.results = [];
      state.npiLinkages = [];
      state.evaType = null;
      state.linkageOriginalInput = [];
      state.msaSuggestions = [];
      state.evaluationResultDetailRatio = [];
      state.allRollups = {};
      state.resultDetails = initialResultDetails;
      state.billingClassRatio = {};
    },
    resetUploadSuccessFlag(state) {
      state.uploadSuccessFlag = false;
    },
    setAccessDeniedFlag(state, action) {
      state.accessDeniedFlag = action.payload;
    },
    resetAccessDeniedFlag(state) {
      state.accessDeniedFlag = false;
    },
    setPrevVisitState(state, action) {
      state.prevVisitState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(API.search.pending, (state) => {
      state.evaluations = [];
    });
    builder.addCase(API.search.fulfilled, (state, action) => {
      state.evaluations = action.payload.content;
    });
    builder.addCase(API.getById.pending, (state) => {
      state.evaluation = {};
    });
    builder.addCase(API.getById.fulfilled, (state, action) => {
      state.evaluation = action.payload;
      state.currentStatus = action.payload?.status;
      state.evaType = action.payload?.evalType;
    });
    builder.addCase(API.save.fulfilled, (state, action) => {
      state.evaluation = action.payload;
      state.currentStatus = action.payload.status;
      state.shouldRefresh += 1;
    });
    builder.addCase(API.updateEvaluation.fulfilled, (state, action) => {
      state.evaluation = action.payload;
      state.currentStatus = action.payload.status;
    });
    builder.addCase(API.getMsaSuggestions.pending, (state) => {
      state.msaSuggestions = [];
    });
    builder.addCase(API.getMsaSuggestions.fulfilled, (state, action) => {
      const apiRes = action.payload;
      const msaSuggestions = apiRes.map((msaDto) => (
        { label: msaDto.msaName, code: msaDto.msaNo, states: msaDto.stateGroup.split(',') }
      ));
      state.msaSuggestions = msaSuggestions;
    });
    builder.addCase(API.getEvaluationResult.fulfilled, (state, action) => {
      state.results = action.payload;
    });
    builder.addCase(API.getStatusById.fulfilled, (state, action) => {
      state.currentStatus = action.payload;
    });
    builder.addCase(API.getNpiLinkageByEId.fulfilled, (state, action) => {
      const originalRecords = action.payload;
      let filteredRecords = [];
      const linkageOI = [];
      if (originalRecords != null && Array.isArray(originalRecords) && originalRecords.length > 0) {
        originalRecords.forEach((item) => {
          if (item.providerLinkageMethod === 'Original Input') {
            linkageOI.push(item);
          }
        });

        state.linkageOriginalInput = linkageOI;

        filteredRecords = originalRecords.filter((item) => !linkageOI.includes(item));
      }
      state.npiLinkages = filteredRecords;
    });
    builder.addCase(API.updateTheDetailInclusion.fulfilled, (state) => {
      state.detailChangedTrigger = !state.detailChangedTrigger;
    });
    builder.addCase(API.bulkUpdateTheDetailInclusion.fulfilled, (state) => {
      state.detailChangedTrigger = !state.detailChangedTrigger;
    });
    builder.addCase(API.getEvaluationResultDetailRatio.fulfilled, (state, action) => {
      const response = action.payload;
      response.sort((a, b) => a.qualityScore - b.qualityScore);
      state.evaluationResultDetailRatio = response;
    });
    builder.addCase(API.getBillingClassRatio.fulfilled, (state, action) => {
      state.billingClassRatio = action.payload;
    });
    builder.addCase(API.deleteEvaluationById.fulfilled, (state) => {
      state.shouldRefresh += 1;
    });
    builder.addCase(API.getAllCustomizedWeights.fulfilled, (state, action) => {
      state.customizedWeights = action.payload;
    });
    builder.addCase(API.saveWeightsFile.fulfilled, (state, action) => {
      state.customizedWeights = action.payload;
      state.uploadSuccessFlag = true;
    });
    builder.addCase(API.getAllRollups.pending, (state) => {
      state.rollupMap = {};
    });
    builder.addCase(API.getAllRollups.fulfilled, (state, action) => {
      const rollupMap = {};
      const totalRollups = action.payload;
      totalRollups.forEach((rollup) => {
        const nwCode = rollup.targetNetworkCode;
        // eslint-disable-next-line no-unused-expressions
        rollupMap[nwCode] ? rollupMap[nwCode].push(rollup) : rollupMap[nwCode] = [rollup];
      });
      state.allRollups = rollupMap;
    });
    builder.addCase(API.getEvaluationResultDetails.pending, (state) => {
      state.isResultLoading = true;
      state.resultDetails = initialResultDetails;
    });
    builder.addCase(API.getEvaluationResultDetails.fulfilled, (state, action) => {
      state.resultDetails = action.payload;
      state.isResultLoading = false;
    });
    builder.addCase(API.togglePrivateStudy.fulfilled, (state) => {
      state.shouldRefresh += 1;
    });
    builder.addCase(API.saveCensusFile.fulfilled, (state) => {
      state.uploadSuccessFlag = true;
    });
  },
});

export const {
  resetEvaluation, resetUploadSuccessFlag, setAccessDeniedFlag, resetAccessDeniedFlag,
  setPrevVisitState,
} = evaluationSlice.actions;
export default evaluationSlice.reducer;
