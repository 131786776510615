import { useState, useEffect, useMemo } from 'react';
import { getComparator, stableSort } from 'common/util/commonUtil';

export default function useCustomSort({ initialData, shouldSort, customSortFlag }) {
  const [sortedData, setSortedData] = useState(initialData);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('');
  const [activeSort, setActiveSort] = useState(true);
  const handleSortState = (property) => {
    if (orderBy === property) {
      if (activeSort && order === 'asc') {
        setOrder('desc');
      } else if (activeSort && order === 'desc') {
        setActiveSort(false);
        setOrderBy('');
        setSortedData(initialData);
      } else {
        setOrder('asc');
        setActiveSort(true);
      }
    } else {
      setOrder('asc');
      setOrderBy(property);
      setActiveSort(true);
    }
  };

  const sortedRows = useMemo(
    () => {
      // custom sorting logic for compare to Medicare provider/market study
      if (customSortFlag === true) {
        const sortedName = Object.entries(initialData)
          .sort(([keyA, valuesA], [keyB, valuesB]) => {
            const a = JSON.parse(keyA)[orderBy];
            const b = JSON.parse(keyB)[orderBy];
            if (typeof a === 'string') { // sorting the name column
              return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
            }
            // sorting the weight column
            const valA = valuesA[0][orderBy];
            const valB = valuesB[0][orderBy];
            return order === 'asc' ? valA - valB : valB - valA;
          })
          .reduce((acc, [key, values]) => { // rebuild the original structure
            // eslint-disable-next-line no-param-reassign
            acc[key] = values;
            return acc;
          }, {});
        return sortedName;
      }
      return stableSort(initialData, getComparator(order, orderBy));
    },
    [order, orderBy, shouldSort],
  );

  useEffect(() => {
    setSortedData(sortedRows);
  }, [sortedRows]);

  return {
    sortedData, handleSortState, setOrder, order, setOrderBy, orderBy, setActiveSort, activeSort,
  };
}
