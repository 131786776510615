import { Paper, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { makeStyles } from '@mui/styles';
import './detailDialog.css';

const useStyles = makeStyles(() => ({
  expandedRow: {
    paddingLeft: '58px',
    paddingTop: '10px',
    textAlign: 'left',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    backgroundColor: '#EEF0F3',
    overflowWrap: 'break-word',
  },
}));

export default function DetailItemPaper({ data }) {
  const paperRef = useRef();
  const styleRef = useStyles();

  return (
    <Paper
      key={`${data.code} title`}
      ref={paperRef}
      elevation={0}
      square
      class={styleRef.expandedRow}
      >
      <Typography variant="subtitle2" key={`${data.code} title`}>
        {data.title}
      </Typography>
      <Typography variant="body2" key={`${data.code} value`} sx={{ marginBottom: 'auto', textAlign: 'left' }}>
        {data.value === null || data.value === '' ? 'N/A' : data.value}
      </Typography>
    </Paper>
  );
}
