import React from 'react';
import { Typography, TableRow, TableBody, IconButton, Collapse, TableCell, Table, TableHead } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { EVALTYPE } from 'common/Constants';
import { FORMAT_NUMBER_TO_DECIMAL_PLACES } from 'common/util/commonUtil';
import NetworkResult from './NetworkResult';
import PopulationCategory from './PopulationCategory';
import {
  BorderTableCell,
  PopStudyTableCell,
  TableCellNetworkValues, TableRowStyled, TypographyMsaStyledName,
} from './PopulationStyles';

const EvalTypeFieldMap = {
  [EVALTYPE.POPULATION]: {
    codeType: 'billingClass',
    code: 'category',
  },
  [EVALTYPE.PROVIDER]: {
    codeType: 'billingCodeType',
    code: 'billingCode',
  },
  [EVALTYPE.MARKET]: {
    codeType: 'billingCodeType',
    code: 'billingCode',
  },
};

function PopulationRows({ msa }) {
  return (
    <>
      <TableCell align="left" sx={{ padding: '0px 16px 0px 6px', width: '40%', border: 'none' }}>
        <TypographyMsaStyledName variant="h6">{msa.name}</TypographyMsaStyledName>
      </TableCell>
      <PopStudyTableCell align="right">
        <Typography>{`${msa.msaPopulation.toLocaleString('en-US')}`}</Typography>
      </PopStudyTableCell>
      <PopStudyTableCell align="right">
        <Typography>{`${msa.populationPercent}%`}</Typography>
      </PopStudyTableCell>
    </>
  );
}

function StudyRows({ msa, msaLevelPopulation }) {
  return (
    <>
      <TableCell align="left" sx={{ padding: '0px 16px 0px 6px', width: '70%', border: 'none' }}>
        <TypographyMsaStyledName variant="h6">{msa?.name}</TypographyMsaStyledName>
      </TableCell>
      <PopStudyTableCell align="right" sx={{ width: '30%' }}>
        <Typography>{msaLevelPopulation.weight > 0 ? (![null, undefined].includes(msaLevelPopulation?.weight) && `${FORMAT_NUMBER_TO_DECIMAL_PLACES(msaLevelPopulation.weight * 100, 0)}%`) : '0%'}</Typography>
      </PopStudyTableCell>
    </>
  );
}

export default function PopulationMSA({ msa,
  msaPopulationResult,
  networkSummaries,
  evalType,
  billingClass,
  handleExpandDetails,
  expandDetails }) {
  const recordTypeCheckers = (type) => {
    const { codeType, code } = EvalTypeFieldMap[type];

    return {
      isMsaRecord: (record) => record[codeType] === null && record[code] === null,
      isCategoryRecord: (record) => record[codeType] !== null && record[code] !== null,
    };
  };

  const { isMsaRecord, isCategoryRecord } = recordTypeCheckers(evalType);

  const msaLevelPopulation = msaPopulationResult.find(isMsaRecord);
  const categoryLevelPopulations = msaPopulationResult.filter(isCategoryRecord);

  const evalTypeRows = {
    [EVALTYPE.POPULATION]: PopulationRows,
    [EVALTYPE.PROVIDER]: StudyRows,
    [EVALTYPE.MARKET]: StudyRows,
  };

  const MainRows = evalTypeRows[evalType];

  return (
    <TableBody>
      <TableRowStyled>
        <BorderTableCell>
          <Table sx={{ tableLayout: 'fixed' }}>
            <TableHead />
            <TableBody>
              <TableRow>
                <TableCell align="left" sx={{ padding: '0', width: '5%', border: 'none' }}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleExpandDetails(!expandDetails)}
                    sx={{ padding: '0', visibility: categoryLevelPopulations.length > 0 ? 'visible' : 'hidden' }}
                  >
                    {expandDetails ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </TableCell>
                <MainRows msa={msa} msaLevelPopulation={msaLevelPopulation} />
              </TableRow>
            </TableBody>
          </Table>
        </BorderTableCell>
        {(networkSummaries || []).map((item) => (
          <TableCellNetworkValues
            key={item.network.code}>
            <NetworkResult
              networkResult={msaLevelPopulation.studyResultNetwork[item.network.code]}
              evalType={evalType}
              billingClass={billingClass}
            />
          </TableCellNetworkValues>
        ))}
      </TableRowStyled>
      <TableRow>
        <PopStudyTableCell
          colSpan={5}
          sx={{ paddingRight: '0px', paddingLeft: '0px !important' }}>
          <Collapse in={expandDetails} timeout="auto" unmountOnExit>
            <PopulationCategory
              key={msa?.name}
              msa={msa}
              categoryLevelPopulations={categoryLevelPopulations}
              networkSummaries={networkSummaries}
              evalType={evalType}
              billingClass={billingClass} />
          </Collapse>
        </PopStudyTableCell>
      </TableRow>
    </TableBody>
  );
}
