import { createSlice } from '@reduxjs/toolkit';
import * as API from './service';

export const homeSlice = createSlice({
  name: 'Home',
  initialState: {
    summaryInfo: {},
    networkInfo: [],
    prevSelectedMSA: null,
    prevSelectedState: null,
    prevSelectedMSASort: null,
    prevSelectedStateSort: null,
    prevSelectedAllPlanSort: null,
  },
  reducers: {
    setPrevSelectedMSA(state, action) {
      state.prevSelectedMSA = action.payload;
    },
    setPrevSelectedState(state, action) {
      state.prevSelectedState = action.payload;
    },
    setPrevSelectedStateSort(state, action) {
      state.prevSelectedStateSort = action.payload;
    },
    setPrevSelectedMSASort(state, action) {
      state.prevSelectedMSASort = action.payload;
    },
    setPrevSelectedAllPlanSort(state, action) {
      state.prevSelectedAllPlanSort = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(API.getBannerInfo.fulfilled, (state, action) => {
      state.summaryInfo = action.payload;
    });
    builder.addCase(API.getTopNetworksInfo.fulfilled, (state, action) => {
      state.networkInfo = action.payload;
    });
  },
});

export const { setPrevSelectedMSA } = homeSlice.actions;

export const { setPrevSelectedState } = homeSlice.actions;

export const { setPrevSelectedMSASort } = homeSlice.actions;

export const { setPrevSelectedStateSort, setPrevSelectedAllPlanSort } = homeSlice.actions;

export default homeSlice.reducer;
