import React, { forwardRef } from 'react';
import MaterialTable, { MTableCell } from '@material-table/core';
import SortIndicator from 'common/components/SortIndicator';

const disableOverlayLoading = () => null;

const renderCell = (cellProps) => {
  const { columnDef } = cellProps;
  if (columnDef.alignRight) {
    return (
      <MTableCell {...cellProps} style={{ textAlign: 'right' }}>
        {columnDef.value}
      </MTableCell>
    );
  }
  return <MTableCell {...cellProps} />;
};

export default function CustomizedMaterialTable(props) {
  return (
    <MaterialTable
      {...props}
      icons={{ SortArrow:
        forwardRef((fwdProps, ref) =>
          <SortIndicator {...fwdProps} ref={ref} />) }}
      components={{
        OverlayLoading: disableOverlayLoading,
        Cell: renderCell,
      }}
    />
  );
}
