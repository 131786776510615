import { styled } from '@mui/material/styles';
import { Box, Divider, Grid, Stack } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import store from 'app/store';
import { getAllNetworks } from 'features/common/state/service';
import { EVALTYPE, NO_RESULT_TO_DISPLAY } from 'common/Constants';
import DataUsabilityRatingLink from 'common/components/DataUsabilityRatingLink';
import EvaluationResultDetailMainCard from './evaluationResultDetailMainCard';
import ResultDetail from '../result/resultDetail';
import EvaluationResultDetailedComparsion from './evaluationResultDetailedComparsion';
import { getAllRollups, getEvaluationResultDetails, getBillingClassRatio } from '../state/service';

export const SummaryWrapperBox = styled(Box)({
  marginBottom: '0',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
});

export default function EvaluationResult({ data, main, selectedTab, setSelectedTab }) {
  const [compareNw, setCompareNw] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const allRollups = useSelector((state) => state.evaluation.allRollups);
  const totalResultDetails = useSelector((state) =>
    state.evaluation.resultDetails.evaluationResultDetails);
  const isResultLoading = useSelector((state) => state.evaluation.isResultLoading);

  const handleClick = (item) => {
    const dto = {
      evaluationId: item.evaluationId,
      targetNetworkCode: item.networkCode,
    };
    setSelected(dto);
    store.dispatch(getBillingClassRatio(dto));
    setCompareNw(item.networkCode);
  };

  const resultDetail = useMemo(() => {
    if (allRollups[compareNw] && totalResultDetails && Object.keys(totalResultDetails).length > 0) {
      return (
        <Stack width={1}>
          <EvaluationResultDetailedComparsion
            mainNwCode={main?.networkCode}
            targetNwCode={compareNw}
          />
          <ResultDetail
            rollupData={allRollups[compareNw]}
            totalDetails={totalResultDetails}
            setParentSelectedTab={setSelectedTab}
          />
        </Stack>
      );
    }
    if (!isResultLoading) {
      return <p style={{ color: '#eb4034', fontWeight: '500', paddingLeft: '20px' }}>{NO_RESULT_TO_DISPLAY}</p>;
    }
    return null;
  }, [allRollups, compareNw, totalResultDetails, main.networkCode, isResultLoading, selectedTab]);

  useEffect(() => {
    store.dispatch(getAllNetworks());
  }, []);

  useEffect(() => {
    store.dispatch(getEvaluationResultDetails(
      { evaluationId: main.id, targetNetworkCode: compareNw },
    ));
  }, [main.id, compareNw]);

  useEffect(() => {
    if (main.id && main.id > 0) {
      store.dispatch(getAllRollups(main.id));
    }
  }, [main]);

  useEffect(() => {
    if (data && data?.length > 0) {
      handleClick(data[0]);
    }
  }, [data]);

  return (
    <>
      <Grid item xs={12}>
        <SummaryWrapperBox>
          {data.map((item, index) => (
            <EvaluationResultDetailMainCard
              // eslint-disable-next-line react/no-array-index-key
              key={`eSum-${index}`}
              data={item}
              selected={item.evaluationId === selected?.evaluationId
                && item.networkCode === selected?.targetNetworkCode}
              handleClick={() => handleClick(item)}
              isMarketStudy={main.evalType === EVALTYPE.MARKET}
            />
          ))}
        </SummaryWrapperBox>
      </Grid>
      {main.evalType === EVALTYPE.MARKET && (
        <Grid item xs={12}>
          <DataUsabilityRatingLink />
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider style={{ width: '100%' }} />
      </Grid>
      <Grid item xs={12}>
        {resultDetail}
      </Grid>
    </>
  );
}
