import React from 'react';
import {
  Tab,
  Box,
  Divider,
} from '@mui/material';
import { BoxHeaderStyled, TabsStyled } from './TabStyles';

export default function HeaderTabs({ selectedTab, setSelectedTab }) {
  return (
    <BoxHeaderStyled display="flex" flexDirection="column" style={{ width: '100%' }}>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        justifyContent="space-between"
      >
        <TabsStyled
          value={selectedTab}
          onChange={(_e, val) => setSelectedTab(val)}
          textColor="secondary"
        >
          <Tab label="Institutional" value="institutional" />
          <Tab label="Professional" value="professional" />
        </TabsStyled>
      </Box>
      <Divider />
    </BoxHeaderStyled>
  );
}
