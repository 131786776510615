import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'app/router/RoutePath';
import Spinner from 'common/components/spinner';
import { CLIENT_PORTAL } from 'common/AppConstant';

export default function ClientPortalRedirection() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!CLIENT_PORTAL) {
      navigate(RoutePath.login.path);
    } else {
      window.location.href = CLIENT_PORTAL;
    }
  }, []);

  return (
    <Spinner />
  );
}
