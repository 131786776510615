import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    systemMsg: null,
    systemMsgSeverity: 'error',
    isFetchingQueue: 0,
    showLogoutDialog: false,
    inlinePageError: null,
  },
  reducers: {
    updateSystemErrorMsg(state, action) {
      state.systemMsg = action.payload;
      state.systemMsgSeverity = 'error';
    },
    updateSystemMsg(state, action) {
      state.systemMsg = action.payload;
      state.systemMsgSeverity = 'info';
    },
    increaseFetchingQueue(state) {
      state.isFetchingQueue += 1;
    },
    decreaseFetchingQueue(state) {
      state.isFetchingQueue -= 1;
    },
    openLogoutDialog(state) {
      state.showLogoutDialog = true;
    },
    closeLogoutDialog(state) {
      state.showLogoutDialog = false;
    },
    setInlinePageError(state, action) {
      state.inlinePageError = action.payload;
    },
    clearInlinePageError(state) {
      state.inlinePageError = null;
    },
  },
});

export const {
  updateSystemErrorMsg, updateSystemMsg, increaseFetchingQueue, decreaseFetchingQueue,
  openLogoutDialog, closeLogoutDialog, setInlinePageError, clearInlinePageError,
} = appSlice.actions;
export default appSlice.reducer;
