import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const fetchSystemDate = createAsyncThunk('system/date', async () => {
  const response = await axios.get(`${MAIN_HOST}/system/date`);
  return response.data;
});

export default fetchSystemDate;
