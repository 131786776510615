import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Tooltip } from '@mui/material';
import CustomRating from 'common/components/CustomRating';
import { FORMAT_PERCENTAGE } from 'common/util/commonUtil';
import CustomInfoIcon from 'common/components/CustomInfoIcon';
import NetworkSummaryPaper from '../../NetworkSummaryPaper';
import { summaryBoxStyles, summaryPercentStyles, comparisonDescriptionStyles } from '../../evaluationResultDetailMainCard';
import NetworkSummaryTitle from '../../NetworkSummaryTitle';

const StyledSummaryBox = styled(Box)({ ...summaryBoxStyles, minHeight: '155px' });
const MedicarePercentage = styled(Typography)({ ...summaryPercentStyles });
const ComparisonText = styled(Typography)({ ...comparisonDescriptionStyles });

export function NoDataText() {
  return <Typography style={{ fontSize: '.8rem', marginTop: '1rem' }}> No Data </Typography>;
}

function SummaryBody({ percentMedicare, usabilityRating, index }) {
  if (!Number.isFinite(percentMedicare)) return <NoDataText />;
  return (
    <>
      <MedicarePercentage key={`medicarePercentage-${index}`}>
        {FORMAT_PERCENTAGE(percentMedicare, 0)}
      </MedicarePercentage>
      <ComparisonText>Compared to Medicare Rates</ComparisonText>
      <Typography style={{ position: 'absolute', bottom: '.5rem', fontSize: '.8rem', display: 'flex' }}>
        <Tooltip title="Data Usability Rating">
          <Typography>
            <CustomRating
              key={`usabilityRating-${index}`}
              ratingProps={{ name: 'usabilityRating', readOnly: true, value: usabilityRating, marginTop: '1px' }}
            />
          </Typography>
        </Tooltip>
        <CustomInfoIcon title="A measure of procedure codes with sufficient rate data" svgProps={{ height: '20px' }} iconBtnStyles={{ marginTop: '-5px' }} />
      </Typography>
    </>
  );
}
export default function NetworkSummaryCard({ index, summary }) {
  return (
    <NetworkSummaryPaper style={{ minHeight: '155px' }}>
      <StyledSummaryBox>
        <NetworkSummaryTitle title={summary.network.label} />
        <SummaryBody {...summary} index={index} />
      </StyledSummaryBox>
    </NetworkSummaryPaper>
  );
}
