import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import store from 'app/store';
import { INVALID_FILE_NAME, THIS_FIELD_IS_REQUIRED } from 'common/ErrorConstants';
import { useSelector } from 'react-redux';
import { getMenuCounts } from 'features/count/state/service';
import { clearInlinePageError } from 'app/state/reducer';
import { RESPONSE_STATUS_SUCCESS } from 'common/Constants';
import { clearStatus, searchToDownload } from '../state/service';

export default function DownloadConfirmDialog({ data, open, onClose }) {
  const [customName, setCustomName] = useState(null);
  const [isNotValidCustomName, setIsNotValidCustomName] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const errorMessage = useSelector((state) => state.app.inlinePageError);
  const downloadFileStatus = useSelector((state) => state.search.downloadFileStatus);
  // Below is regular expression of file name.
  const validCustomNameRegExp = /^[A-Za-z0-9!_.*()-]+$/;

  const clearErrorMessages = () => {
    setIsNotValidCustomName(false);
    setValidationMessage('');
    store.dispatch(clearInlinePageError());
  };
  const isValid = (fileCustomName) => {
    clearErrorMessages();
    if (fileCustomName === null || fileCustomName.trim().length === 0) {
      setIsNotValidCustomName(true);
      setValidationMessage(THIS_FIELD_IS_REQUIRED);
      return false;
    }
    if (!validCustomNameRegExp.test(fileCustomName.trim())) {
      // set the 'IsNotValidCustomname' to true as it is not matching the criteria.
      setIsNotValidCustomName(true);
      setValidationMessage(INVALID_FILE_NAME);
      return false;
    }
    return true;
  };

  const handleSubmitDownload = () => {
    if (data !== null && isValid(customName)) {
      const dto = { ...data, customName: `${customName.trim()}.csv` };
      store.dispatch(searchToDownload(dto));
      setCustomName(null);
      setIsNotValidCustomName(false);
    }
  };

  const handleClose = () => {
    setIsNotValidCustomName(false);
    setValidationMessage('');
    onClose();
    setCustomName(null);
    store.dispatch(clearInlinePageError());
    store.dispatch(clearStatus());
  };

  const updateExportSearchCount = () => {
    const fetchCriteria = {
      fetchEvaluationCount: false,
      fetchSearchCount: true,
      fetchWeightsCount: false,
    };
    store.dispatch(getMenuCounts(fetchCriteria));
  };

  if (downloadFileStatus === RESPONSE_STATUS_SUCCESS) {
    handleClose();
    updateExportSearchCount();
  }

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Name Your Search Export File</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container flexDirection="row" alignItems="flex-end" spacing={2}>
          <Grid item xs={12} style={{ paddingTop: '4px' }}>
            <Grid>
              Creating an export file of your search may take some time depending on
              the amount of results.
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '12px' }}>
              Check the Exported Searches section to track the status or view the exported search.
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="File Name"
              InputProps={{
                endAdornment: <InputAdornment position="end">.csv</InputAdornment>,
              }}
              onChange={(event) => {
                setCustomName(event.target.value);
                isValid(event.target.value);
              }}
              error={isNotValidCustomName}
              helperText={isNotValidCustomName ? validationMessage : ''}
              required
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '100%' }}
            />
          </Grid>
          {
            errorMessage
            && <Grid item xs={12}><Alert severity="error">{errorMessage}</Alert></Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => handleClose()}
        >
          CANCEL
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleSubmitDownload();
          }}
        >
          CREATE EXPORTED SEARCH
        </Button>
      </DialogActions>
    </Dialog>
  );
}
