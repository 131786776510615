import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from '@mui/material';
import store from 'app/store';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import StyledDropzone from 'common/components/styledDropzone';
import { THIS_FIELD_IS_REQUIRED } from 'common/ErrorConstants';
import { getMenuCounts } from 'features/count/state/service';
import { updateSystemMsg, clearInlinePageError, setInlinePageError } from 'app/state/reducer';
import { useSelector } from 'react-redux';
import { resetUploadSuccessFlag } from 'features/evaluation/state/reducer';
// import { saveWeightsFile } from 'features/evaluation/state/service';

export default function FileUploadDialog(props) {
  const {
    open,
    onClose,
    dialogDisplayInfo: { download, href, dialogTitle, textFieldLabel, hintText, maxFileSize },
    saveFileReduxFunction,
  } = props;

  const methods = useForm();
  const { reset, control } = methods;
  const [fileName, setFileName] = useState('');

  const uploadSuccess = useSelector((state) => state.evaluation.uploadSuccessFlag);
  const [fileData, setFileData] = useState(null);
  const errorMessage = useSelector((state) => state.app.inlinePageError);

  const handleClose = () => {
    store.dispatch(resetUploadSuccessFlag());
    store.dispatch(clearInlinePageError());
    setFileData(null);
    onClose(null);
  };

  const handleClickDownloadSample = () => {
    const link = document.createElement('a');
    link.download = download;
    link.href = href;
    link.click();
  };

  const handleConfirmFileUpload = () => {
    const dataInput = { file: fileData, name: fileName };
    store.dispatch(saveFileReduxFunction(dataInput));
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
    store.dispatch(clearInlinePageError());
  };

  // TODO: need to modify this function to include census file count as well
  const updateSidebarCount = () => {
    const fetchCriteria = {
      fetchEvaluationCount: false,
      fetchSearchCount: false,
      fetchWeightsCount: true,
    };
    store.dispatch(getMenuCounts(fetchCriteria));
  };

  useEffect(() => {
    if (uploadSuccess === true) {
      if (dialogTitle === 'Custom Weights') {
        updateSidebarCount();
      }
      store.dispatch(updateSystemMsg('Upload success'));
      handleClose();
    }
  }, [uploadSuccess]);

  useEffect(() => {
    reset();
  }, [open, onClose]);

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            handleClose();
          }
        }}>
        <DialogTitle>{`Upload Your ${dialogTitle} File`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">What do you want to call it?</Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="fileName"
                  id="input-fileName"
                  rules={{
                    required: THIS_FIELD_IS_REQUIRED,
                  }}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label={textFieldLabel}
                      onChange={handleFileNameChange}
                      required
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : null}
                  />
                  )}
                />
              </Grid>
              {
            errorMessage
            && <Grid item xs={12}><Alert severity="error">{errorMessage}</Alert></Grid>
          }
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Controller
                  render={({
                    field: { onChange },
                    fieldState: { error },
                  }) => (
                    <StyledDropzone
                      maxFileSize={maxFileSize}
                      onChange={(files) => {
                        if (typeof onChange === 'function') {
                          onChange(files[0]);
                        }
                      }}
                      onDropAccepted={(files) => {
                        if (files[0] !== undefined && files[0] !== null) {
                          store.dispatch(clearInlinePageError());
                          setFileData(files[0]);
                        }
                      }}
                      onFileDialogCancel={() => {
                        setFileData(null);
                      }}
                      onDropRejected={() => {
                        store.dispatch(
                          setInlinePageError(`The selected file is too large. Please select a file that is ${maxFileSize}MB or smaller.`),
                        );
                      }}
                      error={error}
                    />
                  )}
                  name="input-uploadFile"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {`Not sure how to construct a valid ${hintText} file?`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={handleClickDownloadSample} variant="contained" size="small">
                  <FileDownloadIcon />
                  &nbsp;Download Sample
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => handleClose()}
          >
            Close
          </Button>
          <Button
            color="primary"
            disabled={errorMessage || (!fileData || !fileName.trim())}
            onClick={() => {
              handleConfirmFileUpload();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
