import { Grid, Autocomplete, TextField, Divider, Stack } from '@mui/material';
import { DIVIDER_SOLID, VIEW_PLAN_BY_MSA, HOME_PAGE_TITLE_DARK } from 'common/Constants';
import { useSelector } from 'react-redux';
import React, { useEffect, useState, forwardRef } from 'react';
import store from 'app/store';
import { getAllMsa } from 'features/common/state/service';
import { getMsaSuggestions, getNetworkRating } from 'features/evaluation/state/service';
import SortIndicator from 'common/components/SortIndicator';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import TableCellHeader from 'common/components/TableCellHeader';
import { CustomHomePageTheme, HeaderRow } from 'features/common/Style';
import TableRow from './tableRow';
import { setPrevSelectedMSASort, setPrevSelectedMSA } from '../state/reducer';

export default function ViewPlansByMSATable() {
  const msaOptions = useSelector((state) => state.provider.msaOptions);
  const networks = useSelector((state) => state.provider.networks);
  const prevSelectedMSA = useSelector((state) => state.home.prevSelectedMSA);
  const prevSelectedMSASort = useSelector((state) => state.home.prevSelectedMSASort);
  const allMsaSuggestions = useSelector((state) => state.evaluation.msaSuggestions);
  const [networkWithRating, setNetworkWithRating] = useState([]);
  const [filteredNetworks, setFilteredNetworks] = useState([]);
  const [viewMSAData, setViewMSAData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'networkLabel', direction: '' });
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    store.dispatch(setPrevSelectedMSASort({ key, direction }));
  };

  const fetchNetworkWithRating = (msaNo) => {
    getNetworkRating(msaNo).then((res) => {
      const selectedMSA = allMsaSuggestions?.find((msa) => msa.code === msaNo);
      const filteredNetworkList = networks.filter((nw) => (nw.allState
        || nw.states.some((state) => selectedMSA?.states.includes(state))));
      setFilteredNetworks(filteredNetworkList);
      setNetworkWithRating(res);
      if (msaNo !== prevSelectedMSA?.msaNo) {
        setSortConfig({ key: 'networkLabel', direction: 'asc' });
        store.dispatch(setPrevSelectedMSASort({ key: 'networkLabel', direction: 'asc' }));
      }
    });
  };

  useEffect(() => {
    store.dispatch(getAllMsa());
    store.dispatch(getMsaSuggestions());
  }, []);

  useEffect(() => {
    if (prevSelectedMSA !== null) {
      fetchNetworkWithRating(prevSelectedMSA?.msaNo);
    }
    if (prevSelectedMSASort != null) {
      setSortConfig(prevSelectedMSASort);
    }
  }, []);

  // get the sorted merged list after for plan type and rated network
  useEffect(() => {
    const mergedNetworks = networkWithRating.map((elem) => {
      const match = filteredNetworks.find((r) => r.code === elem.network);
      return match && { ...elem, networkLabel: match.label, planType: match.planType };
    });
    mergedNetworks.sort((a, b) => a.networkLabel.localeCompare(b.networkLabel));
    setViewMSAData(mergedNetworks);
  }, [networkWithRating]);

  const sortedViewMSAData = React.useMemo(() => {
    if (sortConfig.key === 'networkLabel' && sortConfig.direction === 'asc') {
      viewMSAData.sort((a, b) => (a.networkLabel.localeCompare(b.networkLabel)));
    } else if (sortConfig.key === 'networkLabel' && sortConfig.direction === 'desc') {
      viewMSAData.sort((a, b) => (b.networkLabel.localeCompare(a.networkLabel)));
    } else if (sortConfig.key === 'rating' && sortConfig.direction === 'asc') {
      viewMSAData.sort((a, b) => (a.rating - b.rating));
    } else if (sortConfig.key === 'rating' && sortConfig.direction === 'desc') {
      viewMSAData.sort((a, b) => (b.rating - a.rating));
    }
    return viewMSAData;
  }, [viewMSAData, sortConfig]);

  const customAppTheme = useTheme();
  const pageTheme = CustomHomePageTheme(customAppTheme);

  return (
    <>
      <Grid item xs={9}>
        <Autocomplete
          id="msaSelector"
          size="small"
          disableClearable
          fullWidth
          options={msaOptions.filter((item) => item?.msaName && item?.msaName !== '')}
          value={prevSelectedMSA}
          onChange={(_, data) => {
            fetchNetworkWithRating(data?.msaNo);
            store.dispatch(setPrevSelectedMSA(data));
          }}
          getOptionLabel={(option) => {
            if ('msaName' in option) {
              if (typeof option.msaName === 'string' && option.msaName.endsWith(' MSA')) {
                return option.msaName.slice(0, -4);
              }
              return option.msaName;
            }
            return null;
          }}
          isOptionEqualToValue={(opt, val) => opt.msaNo === val.msaNo}
          renderInput={(props) => (
            <TextField
              {...props}
              label="View MSA"
              placeholder="Select MSA"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack>
          <Grid
            container
            sx={{ alignItems: 'flex-end', flexDirection: 'row', display: 'flex', flexWrap: 'nowrap', paddingTop: '1rem' }}
          >
            <Grid item xs={6}>
              <ThemeProvider theme={pageTheme}>
                <TableCellHeader
                  key="networkLabel"
                  label="Plan"
                  sortDirection={sortConfig.direction}
                  active={sortConfig.key === 'networkLabel'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('networkLabel')}
                  IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} height="24px" />)}
                  headerColor={HOME_PAGE_TITLE_DARK}
                  sx={{ alignItems: 'flex-end' }}
                  variant="h6"
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={3}>
              <HeaderRow>Plan Type</HeaderRow>
            </Grid>
            <Grid item xs={3}>
              <ThemeProvider theme={pageTheme}>
                <TableCellHeader
                  key="rating"
                  label="Data Usability Rating"
                  sortDirection={sortConfig.direction}
                  active={sortConfig.key === 'rating'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('rating')}
                  IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} marginRight="25px" height="24px" />)}
                  headerColor={HOME_PAGE_TITLE_DARK}
                  sx={{ alignItems: 'flex-end' }}
                  headerSX={{ width: '100px' }}
                  variant="h6"
                />
              </ThemeProvider>
            </Grid>
          </Grid>
          <Grid item>
            <Divider
              orientation="horizontal"
              sx={{
                height: '0.25rem',
                borderBottomWidth: '2px',
                borderBottomColor: DIVIDER_SOLID,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {sortedViewMSAData.length > 0
              && sortedViewMSAData.filter((n) => n !== null && n !== undefined).map((n, idx) => (
                <TableRow
                  key={n.network}
                  networkRowData={n}
                  index={idx}
                  dataLength={viewMSAData.length}
                  selectedTab={VIEW_PLAN_BY_MSA}
                />
              ))}
          </Grid>
        </Stack>
      </Grid>
    </>
  );
}
