import { createSlice } from '@reduxjs/toolkit';
import * as API from './service';

export const providerSlice = createSlice({
  name: 'settings',
  initialState: {
    providers: [],
    networks: [],
    states: [],
    msaOptions: [],
    networkMap: {},
    planTypes: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(API.getProviders.fulfilled, (state, action) => {
      state.providers = action.payload;
    });
    builder.addCase(API.getNetworks.fulfilled, (state, action) => {
      state.networks = action.payload;
    });
    builder.addCase(API.getStates.fulfilled, (state, action) => {
      state.states = action.payload;
    });
    builder.addCase(API.getAllMsa.fulfilled, (state, action) => {
      state.msaOptions = action.payload;
    });
    builder.addCase(API.getAllNetworks.pending, (state) => {
      state.networkMap = {};
    });
    builder.addCase(API.getAllNetworks.fulfilled, (state, action) => {
      const networkMap = {};
      action.payload.forEach((rv) => {
        networkMap[rv.code] = rv.label;
      });
      state.networkMap = networkMap;
    });
    builder.addCase(API.getAllPlanType.fulfilled, (state, action) => {
      state.planTypes = action.payload;
    });
  },
});

export const { updateSelectedProviders } = providerSlice.actions;
export default providerSlice.reducer;
