/*
Custom rating component.
*/
import React from 'react';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)(({ outlineColor, filledStarColor }) => ({
  '& .MuiRating-icon': {
    color: `${outlineColor}`,
  },
  '& .MuiRating-iconFilled': {
    color: `${filledStarColor}`,
  },
}));

export default function CustomRating({ ratingProps }) {
  const {
    readOnly,
    marginTop,
    precision,
    value,
    name,
    starColor,
    outlineColor,
  } = ratingProps;
  return (
    <StyledRating
      value={value}
      precision={precision || 0.5}
      readOnly={readOnly}
      marginTop={marginTop}
      name={name}
      filledStarColor={starColor || '#CF8500'}
      outlineColor={outlineColor || '#6A7585'}
     />
  );
}
