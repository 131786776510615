import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { saveWeightsFile } from 'features/evaluation/state/service';
import { deleteWeightsById, toggleWeightSetPrivacy } from './service';

export const weightsSlice = createSlice({
  name: 'Weights',
  initialState: {
    refreshFlag: true,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        saveWeightsFile.fulfilled,
        deleteWeightsById.fulfilled,
        toggleWeightSetPrivacy.fulfilled,
      ),
      (state) => {
        state.refreshFlag = !state.refreshFlag;
      },
    );
  },
});

export default weightsSlice.reducer;
