import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const getBannerInfo = createAsyncThunk('home/summary', async () => {
  const response = await axios.get(`${MAIN_HOST}/home/summary`);
  return response.data;
});

export const getTopNetworksInfo = createAsyncThunk('/home/top-networks', async () => {
  const response = await axios.get(`${MAIN_HOST}/home/top-networks`);
  return response.data;
});

export default getBannerInfo;
