import { createSlice } from '@reduxjs/toolkit';
import { saveCensusFile } from 'features/evaluation/state/service';
import * as API from './service';
import { deleteCensusById } from './service';

export const censusSlice = createSlice({
  name: 'census',
  initialState: {
    censusList: [],
    uploadedCensus: {},
    refreshFlag: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      API.getCensusList.fulfilled,
      (state, action) => {
        state.censusList = Array.isArray(action.payload) ? action.payload : [];
      },
    );
    builder.addCase(
      saveCensusFile.fulfilled,
      (state, action) => {
        state.uploadedCensus = action.payload;
        state.refreshFlag = !state.refreshFlag;
      },
    );
    builder.addCase(
      deleteCensusById.fulfilled,
      (state) => {
        state.refreshFlag = !state.refreshFlag;
      },
    );
  },
});

export default censusSlice.reducer;
