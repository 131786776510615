import React from 'react';
import { Tooltip } from '@mui/material';
import { CLIENT_PORTAL_CUSTOMER_SERVICE } from 'common/AppConstant';

export function CONVERT_OBJECT_ARRAY_COMMA_STRING(value, property, mapping = null) {
  if (value && Array.isArray(value)) {
    // eslint-disable-next-line react/destructuring-assignment
    const result = value.map((item) => {
      const originalValue = item[property];

      if (mapping) {
        return mapping[originalValue] || originalValue;
      }
      return originalValue;
    });
    return result && Array.isArray(result) ? result.join(', ') : [];
  }
  return null;
}

export default CONVERT_OBJECT_ARRAY_COMMA_STRING;

export const FORMAT_RATE = (raw) => {
  if (!Number.isFinite(raw)) {
    return '-';
  }
  const subject = parseFloat(raw).toFixed(2);
  const result = subject.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  return `$${result}`;
};

export function FORMAT_NUMBER_TO_DECIMAL_PLACES(raw, fixDigitForDecimal) {
  return raw.toFixed(fixDigitForDecimal);
}

export const FORMAT_NATURAL = (raw) => {
  if (!Number.isFinite(raw)) {
    return '-';
  }
  const subject = raw.toString();
  const result = subject.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  return `${result}`;
};

export const FORMAT_INTEGER_NUM = (raw) => {
  if (!Number.isFinite(raw)) {
    return '-';
  }
  const numberStr = raw.toString();
  const periodIndex = numberStr.indexOf('.');
  const integerStr = numberStr.substring(0, periodIndex);
  const decimalStr = numberStr.substring(periodIndex, numberStr.length);
  const result = integerStr.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  return `${result + decimalStr}`;
};

export const FORMAT_PERCENTAGE = (raw, fixDigits = 2, defaultReturn = '-') => {
  if (!Number.isFinite(raw)) {
    return defaultReturn;
  }
  const percentage = parseFloat(raw * 100).toFixed(fixDigits);

  return `${percentage}%`;
};

export function FORMAT_RATIO(raw, benchmark) {
  if (!Number.isFinite(raw)) {
    return <span>-</span>;
  }

  const value = `${raw}%`;

  return (
    <Tooltip title={`Benchmark: ${FORMAT_RATE(benchmark)}`}>
      <span>{value}</span>
    </Tooltip>
  );
}

export function convertTimestamp(timestamp) {
  const date = new Date(timestamp);
  return (timestamp
    ? (
      <span>
        {' '}
        {timestamp ? date.toLocaleString() : null}
        {' '}
      </span>
    ) : <span />
  );
}

export function convertDatetime(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear().toString();
  const month = date.getMonth() + 1;
  const convertedMonth = month < 10 ? `0${month.toString()}` : month.toString();
  const day = date.getDate() < 10 ? `0${date.getDate().toString()}` : date.getDate().toString();
  return (timestamp
    ? (
      <span>
        {' '}
        {timestamp ? `${year}-${convertedMonth}-${day}` : null}
        {' '}
      </span>
    ) : <span />
  );
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === null || bytes === undefined) return '-';
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function hasPermission(permission, permissionList) {
  return permissionList && Array.isArray(permissionList)
    ? permissionList.includes(permission) : false;
}

export function FORMAT_BIG_NUMBER(raw) {
  if (raw >= 1e12) {
    return `${`${(raw / 1e12).toFixed(0)}T+`}`;
  }
  if (raw >= 1e9) {
    return `${`${(raw / 1e9).toFixed(0)}B+`}`;
  }
  if (raw >= 1e6) {
    return `${`${(raw / 1e6).toFixed(0)}M+`}`;
  }
  if (raw >= 1e3) {
    return `${`${(raw / 1e3).toFixed(0)}K+`}`;
  }
  return raw;
}

export const capitalizePhrase = (phrase) => phrase
  .toLowerCase()
  .split(' ')
  .map((word) => {
    const trimmedWord = word.trim();
    return trimmedWord.charAt(0).toUpperCase() + trimmedWord.substring(1);
  })
  .join(' ');

export const openCustomerService = () => window.open(CLIENT_PORTAL_CUSTOMER_SERVICE, '_blank', 'noreferrer');

export function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    return b[orderBy].localeCompare(a[orderBy]);
  }
  return b[orderBy] - a[orderBy];
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function padStartToNumber(number, targetLength = 2, padString = '0') {
  return String(number).padStart(targetLength, padString);
}
