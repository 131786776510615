import React from 'react';
import { Typography, Table, TableHead, TableRow } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { EVALTYPE, DUR_GOLDEN_STAR_COLOR } from 'common/Constants';
import { CustomNetworkResultsHeaderTheme } from 'features/common/Style';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { NetworkSubColumnCell } from './PopulationStyles';

function Column({ columnName, width, align = 'center', sx = {} }) {
  return (
    <NetworkSubColumnCell width={width} align={align} padding="none" sx={sx}>
      <Typography variant="paraHeader">{columnName}</Typography>
    </NetworkSubColumnCell>
  );
}

function StudyTableColumnNames() {
  return (
    <>
      <Column width="20%" columnName="% Medicare" />
      <Column width="40%" columnName="MRF Rate" align="right" />
      <Column width="20%" columnName="NPI Count" align="right" sx={{ paddingLeft: '15px' }} />
      <Column width="20%" columnName={<StarIcon width="20%" sx={{ color: DUR_GOLDEN_STAR_COLOR, verticalAlign: 'bottom !important' }} />} sx={{ paddingLeft: '15px' }} />
    </>
  );
}

const EvalTypeColumnNames = {
  [EVALTYPE.POPULATION]: () => (
    <>
      <Column width="35%" columnName="% Medicare" />
      <Column width="30%" columnName="NPI Count" />
      <Column width="35%" columnName="Data Usability" />
    </>
  ),
  [EVALTYPE.PROVIDER]: () => <StudyTableColumnNames />,
  [EVALTYPE.MARKET]: () => <StudyTableColumnNames />,
};

export default function NetworkResultsHeader({ evalType }) {
  const customAppTheme = useTheme();
  const pageTheme = evalType !== EVALTYPE.POPULATION
    ? CustomNetworkResultsHeaderTheme(customAppTheme) : customAppTheme;
  return (
    <ThemeProvider theme={pageTheme}>
      <Table sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow sx={{ display: 'flex', alignItems: 'flex-end', verticalAlign: 'bottom !important', height: '40px' }}>
            {EvalTypeColumnNames[evalType]()}
          </TableRow>
        </TableHead>
      </Table>
    </ThemeProvider>
  );
}
