import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
} from 'react-router-dom';
import axios from 'axios';
import { Provider } from 'react-redux';
import { COOKIE_JWT, MAIN_HOST } from 'common/AppConstant';
import { updateSystemErrorMsg, increaseFetchingQueue, decreaseFetchingQueue, setInlinePageError } from 'app/state/reducer';
import { updatePasswordChangeStatus, setErrorMsg } from 'features/login/state/reducer';
import { setAccessDeniedFlag } from 'features/evaluation/state/reducer';
import * as ErrorConstants from 'common/ErrorConstants';
import store, { persistor } from 'app/store';
import { TOKEN_REFRESH_URL } from 'features/login/state/service';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './App.css';

const pattern = new RegExp(`^${MAIN_HOST}/evaluation/status/id/\\d+$`);
const mapAxiosErrorToText = (axiosError) => {
  const UNKNOWN_ERROR = 'An unknown error occured.';

  if (axiosError && axiosError.response) {
    if (axiosError.response.data) {
      const { errorCode } = axiosError.response.data;
      switch (errorCode) {
        case ErrorConstants.DUPLICATED_RECORD:
          return 'Duplicated record is found.';
        default:
      }
    }

    if (axiosError && axiosError.code) {
      switch (axiosError.code) {
        case ErrorConstants.ERROR_CODE.ERR_NETWORK:
          return 'Service is not reachable.';
        default:
      }
    }
    switch (axiosError.response.status) {
      case 400:
        return 'The request was not valid. Please verify the data and try again.';
      case 401:
      case 403:
        localStorage.clear();
        window.location.reload();
        return '';
      case 500:
        return 'There was an internal server error. Please try again later.';
      default:
        return UNKNOWN_ERROR;
    }
  }

  return UNKNOWN_ERROR;
};

axios.interceptors.request.use(
  (config) => {
    if (config?.url?.indexOf('search/suggestion') < 0 && config.url !== TOKEN_REFRESH_URL
      && !pattern.test(config.url)) {
      store.dispatch(increaseFetchingQueue());
    }
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${localStorage.getItem(COOKIE_JWT)}`;

    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    if (response?.config?.url?.indexOf('search/suggestion') < 0 && response?.request?.responseURL !== TOKEN_REFRESH_URL
      && !pattern.test(response?.request?.responseURL)) {
      store.dispatch(decreaseFetchingQueue());
    }
    return response;
  },
  (error) => {
    if (error?.config?.url !== TOKEN_REFRESH_URL) {
      store.dispatch(decreaseFetchingQueue());
    }

    if (error.response.data.errorCode === 'REQUIRED_PASSWORD_CHANGE') {
      store.dispatch(updatePasswordChangeStatus(true));
      return Promise.reject(error);
    }

    if (error.response.data.errorCode
      === ErrorConstants.ERROR_CODE.INACTIVE_USER) {
      store.dispatch(setErrorMsg('Inactive User'));
      return Promise.reject(error);
    }

    if (error.response.data.errorCode
      === ErrorConstants.ERROR_CODE.INVALID_CREDENTIAL) {
      store.dispatch(setErrorMsg('Invalid User Credential'));
      return Promise.reject(error);
    }

    if (error.response.data.errorCode === ErrorConstants.ERROR_CODE.INVALID_FORMAT) {
      store.dispatch(updateSystemErrorMsg('Invalid file format. Please follow the file format in the sample.'));
      return Promise.reject(error);
    }

    if (error.response.data.errorCode === ErrorConstants.ERROR_CODE.ACCESS_DENIED) {
      store.dispatch(setAccessDeniedFlag(true));
      return Promise.reject(error);
    }

    if (error.response.data.errorCode === ErrorConstants.ERROR_CODE.INVALID_REQUEST) {
      store.dispatch(setInlinePageError(error.response.data.message));
      return Promise.reject(error);
    }
    store.dispatch(decreaseFetchingQueue());
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    store.dispatch(updateSystemErrorMsg(mapAxiosErrorToText(error)));
    return Promise.reject(error);
  },
);

const root = createRoot(
  document.getElementById('root'),
);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
);
