import React, { useRef } from 'react';
import axios from 'axios';

import { MAIN_HOST } from 'common/AppConstant';
import * as Constants from 'common/Constants';
import { FORMAT_INTEGER_NUM } from 'common/util/commonUtil';
import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';

export default function WeightsDetail({ data }) {
  const tableRef = useRef();

  const columns = [
    {
      title: 'Billing Code Type',
      field: 'billingCodeType',
    },
    {
      title: 'Billing Code',
      field: 'billingCode',
    },
    {
      title: 'Billing Class',
      field: 'billingClass',
    },
    {
      title: 'Utilization',
      field: 'utilization',
      render: (rd) => FORMAT_INTEGER_NUM(rd.utilization),
    },
  ];

  return (
    <CustomizedMaterialTable
      title={data?.name}
      tableRef={tableRef}
      options={{
        pageSize: 20,
      }}
      columns={columns}
      localization={{ toolbar: { searchPlaceholder: 'Filter by Code' } }}
      data={(query) =>
        new Promise((resolve) => {
          axios
            .post(`${MAIN_HOST}/weights/details/${data.id}`, {
              page: query.page,
              size: query.pageSize,
              searchTerm: query.search,
              sortProperty:
                Array.isArray(query.orderByCollection)
                && query.orderByCollection.length === 1
                  ? columns[query.orderByCollection[0].orderBy].field
                  : 'utilization',
              direction:
                Array.isArray(query.orderByCollection)
                && query.orderByCollection.length === 1
                && query.orderByCollection[0].orderDirection
                  ? query.orderByCollection[0].orderDirection.toUpperCase()
                  : Constants.DIRECTION_DESC,
            })
            .then((m) => {
              const resData = m.data;
              resolve({
                data: resData.content,
                page: resData.number,
                size: resData.size,
                totalCount: resData.totalElements,
              });
            })
            .catch(() => {
              resolve({
                data: [],
                page: 0,
                totalCount: 0,
              });
            });
        })
      }
    />
  );
}
