export const THIS_FIELD_IS_REQUIRED = 'This field is required.';

export const FILE_MISSING = 'File is required. Please upload one.';

export default THIS_FIELD_IS_REQUIRED;

export const DUPLICATED_RECORD = 'DUPLICATED_RECORD';

export const INVALID_FILE_NAME = 'This is not a valid file name.Please use alphanumeric and !_.*()- special characters for file name.';

export const ERROR_CODE = {
  INVALID_CREDENTIAL: 'INVALID_CREDENTIAL',
  INACTIVE_USER: 'INACTIVE_USER',
  ERR_NETWORK: 'ERR_NETWORK',
  INVALID_FORMAT: 'INVALID_FORMAT',
  ACCESS_DENIED: 'ACCESS_DENIED',
  INVALID_REQUEST: 'INVALID_REQUEST',
};
