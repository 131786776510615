import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const getFileDownloadUrl = createAsyncThunk(
  'download_geturl',
  async (id) => {
    const { data, headers } = await axios.get(`${MAIN_HOST}/required-file/export/${id}`);
    const filename = headers['content-disposition'];
    return { data, filename };
  },
);

export const regenerateFile = createAsyncThunk(
  'regenerateFile',
  async (id) => {
    const response = await axios.post(`${MAIN_HOST}/required-file/regenerate/${id}`);
    return response.data;
  },
);

export const trackDownload = createAsyncThunk(
  'trackDownload',
  async (id) => {
    const response = await axios.post(`${MAIN_HOST}/required-file/track/${id}`);
    return response.data;
  },
);

export const deleteDownloadById = createAsyncThunk(
  'deleteDownloadById',
  async (id) => {
    const response = await axios.delete(`${MAIN_HOST}/required-file/delete/${id}`);
    return response.data;
  },
);
