import { Button, Grid } from '@mui/material';
import React from 'react';
import { FILE_UPLOAD_PROPS } from 'common/Constants';
import CensusStats from './CensusStats';
import FileUploadDialog from '../../../../../common/components/FileUploadDialog';
import { saveCensusFile } from '../../../state/service';

// container for census statistics and upload button
export default function CensusStatsAndUpload({ censusObj, uploadDialogOpen, setUploadDialogOpen }) {
  return (
    <>
      <Grid container item xs={12} justifyContent="space-between" padding="0 !important" mt={0.5}>
        <Grid item>
          <CensusStats censusObj={censusObj} dialogTitle={FILE_UPLOAD_PROPS.census.dialogTitle} />
        </Grid>
        <Grid item>
          <Button size="small" onClick={() => setUploadDialogOpen(true)}>
            UPLOAD NEW CENSUS SUMMARY
          </Button>
        </Grid>
      </Grid>
      <FileUploadDialog
        open={uploadDialogOpen}
        onClose={() => {
          setUploadDialogOpen(false);
        }}
        dialogDisplayInfo={FILE_UPLOAD_PROPS.census}
        saveFileReduxFunction={saveCensusFile}
      />
    </>
  );
}
