import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

export default function NavigateAwayWarningDialog({ open, onConfirm, onCancel, content }) {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5">
          <WarningIcon sx={{ transform: 'translate(-5px, 5px)' }} />
          {content.title}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container flexDirection="row" alignItems="flex-end" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {content.body}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={onCancel}
        >
          {content.cancel}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onConfirm}
        >
          {content.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
