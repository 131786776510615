/* eslint-disable max-len */
import * as React from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronleftIcon from '@mui/icons-material/ChevronLeft';
import { usePermissions } from 'features/common/hooks/usePermissions';
import RoutePath from 'app/router/RoutePath';
import store from 'app/store';
import { Drawer, DrawerHeader } from 'common/components/navigatorComponents';
import * as Constants from 'common/Constants';
import { PERMISSION } from 'common/Constants';
import { getMenuCounts } from 'features/count/state/service';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { openCustomerService } from 'common/util/commonUtil';
import { ReactComponent as IntelligenceSvgIcon } from 'asset/device-analytics.svg';
import { ReactComponent as SearchRatesSvgIcon } from 'asset/list-search.svg';
import { ReactComponent as HomeSvgIcon } from 'asset/home.svg';
import { ReactComponent as DownloadSvgIcon } from 'asset/file-download.svg';
import { ReactComponent as CustomWeightsSvgIcon } from 'asset/chart-dots-3.svg';
import { ReactComponent as ArrowLeft } from 'asset/arrowLeft.svg';
import { ReactComponent as ArrowRight } from 'asset/arrowRight.svg';
import { trackVisit } from 'features/common/state/service';
import { toggleSideBar } from '../state/reducer';
import { resetEvaluation } from '../../evaluation/state/reducer';
import ParentMenuItem from './ParentMenuItem';
import IntelligenceSubMenu from './IntelligenceSubMenu';

export default function SideBar() {
  const sx = {
    menuItem: {
      backgroundColor: '',
    },
    selectedMenuItem: {
      backgroundColor: Constants.PRIMARY,
      '&:hover': {
        backgroundColor: Constants.PRIMARY,
      },
    },
  };

  const navigate = useNavigate();
  const location = useLocation();

  const sideBarOpen = useSelector((state) => state.navigator.sideBarOpen);

  const { hasPermission } = usePermissions();

  const toggleMenu = () => {
    store.dispatch(toggleSideBar());
  };

  const [selectedIndex, setSelectedIndex] = React.useState(null);

  useEffect(() => {
    const currentPath = location.pathname;
    const intelligencePaths = [
      RoutePath.app.evaluation.path,
      RoutePath.app.evaluation.subpage.createMarketStudy.path,
      RoutePath.app.evaluation.subpage.createProviderStudy.path,
      RoutePath.app.evaluation.subpage.createPopulationStudy.path,
      RoutePath.app.evaluation.subpage.censusFiles.path,
    ];
    if (currentPath === RoutePath.app.search.path) {
      setSelectedIndex(Constants.PAGE_NAME.SEARCH);
      if (location?.state?.source !== Constants.TRACK_VISIT_TYPE.HOME_SEARCH) {
        store.dispatch(trackVisit(Constants.TRACK_VISIT_TYPE.SEARCH));
      }
    } else if (intelligencePaths.includes(currentPath)) {
      setSelectedIndex(Constants.PAGE_NAME.EVALUATION);
      store.dispatch(resetEvaluation());
      if (currentPath === RoutePath.app.evaluation.path) {
        store.dispatch(trackVisit(Constants.TRACK_VISIT_TYPE.EVALUATION));
      }
    } else if (currentPath === RoutePath.app.export.path) {
      setSelectedIndex(Constants.PAGE_NAME.EXPORT);
      store.dispatch(trackVisit(Constants.TRACK_VISIT_TYPE.DOWNLOAD));
    } else if (currentPath === RoutePath.app.weights.path) {
      setSelectedIndex(Constants.PAGE_NAME.WEIGHTS);
      store.dispatch(trackVisit(Constants.TRACK_VISIT_TYPE.WEIGHT));
    } else if (currentPath === RoutePath.app.admin.path) {
      setSelectedIndex(Constants.PAGE_NAME.ADMIN);
    } else if (currentPath === RoutePath.app.home.path) {
      setSelectedIndex(Constants.PAGE_NAME.HOME);
    }
  }, [location]);

  useEffect(() => {
    const fetchCriteria = {
      fetchEvaluationCount: true,
      fetchSearchCount: true,
      fetchWeightsCount: true,
    };
    store.dispatch(getMenuCounts(fetchCriteria));
  }, []);

  const exportCount = useSelector((state) => state.count.searchCount);
  const weightsCount = useSelector((state) => state.count.weightsCount);

  return (
    <Drawer
      variant="permanent"
      open={sideBarOpen}
      sx={{
        flexShrink: 0,
      }}>
      <DrawerHeader>
        <IconButton onClick={() => toggleMenu()}>
          <ChevronleftIcon />
        </IconButton>
      </DrawerHeader>
      <List>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ParentMenuItem
            isMenuSelected={selectedIndex === Constants.PAGE_NAME.HOME}
            menuIcon={<HomeSvgIcon />}
            menuText="Home"
            sideBarOpen={sideBarOpen}
            onMenuCLick={() => navigate(RoutePath.app.home.path)} />
          {hasPermission(PERMISSION.VIEW_SEARCH) && (
          <ParentMenuItem
            isMenuSelected={selectedIndex === Constants.PAGE_NAME.SEARCH}
            menuIcon={<SearchRatesSvgIcon />}
            menuText="Search Rates"
            sideBarOpen={sideBarOpen}
            onMenuCLick={() => navigate(RoutePath.app.search.path)} />
          )}
          {hasPermission(PERMISSION.VIEW_INTELLIGENCE) && (
          <>
            <ParentMenuItem
              isMenuSelected={selectedIndex === Constants.PAGE_NAME.EVALUATION}
              menuIcon={<IntelligenceSvgIcon />}
              menuText="Intelligence"
              sideBarOpen={sideBarOpen}
              onMenuCLick={() => navigate(RoutePath.app.evaluation.path)} />
            {sideBarOpen && <IntelligenceSubMenu sideBarOpen={sideBarOpen} />}
          </>
          )}
          {hasPermission(PERMISSION.DOWNLOAD_SEARCH) && (
          <ParentMenuItem
            isMenuSelected={selectedIndex === Constants.PAGE_NAME.EXPORT}
            menuIcon={<DownloadSvgIcon />}
            menuText="Exported Searches"
            sideBarOpen={sideBarOpen}
            onMenuCLick={() => navigate(RoutePath.app.download.path)}
            itemCount={exportCount} />
          )}
          {hasPermission(PERMISSION.CREATE_INTELLIGENCE) && (
          <ParentMenuItem
            isMenuSelected={selectedIndex === Constants.PAGE_NAME.WEIGHTS}
            menuIcon={<CustomWeightsSvgIcon />}
            menuText="Custom Weights"
            sideBarOpen={sideBarOpen}
            onMenuCLick={() => navigate(RoutePath.app.weights.path)}
            itemCount={weightsCount} />
          )}
          <ParentMenuItem
            isMenuSelected={false}
            menuIcon={<MailOutlineIcon />}
            menuText="Customer Service"
            sideBarOpen={sideBarOpen}
            onMenuCLick={openCustomerService} />
          {hasPermission(PERMISSION.ADMIN) && (
          <ParentMenuItem
            isMenuSelected={selectedIndex === Constants.PAGE_NAME.ADMIN}
            menuIcon={<SettingsIcon />}
            menuText="Settings"
            sideBarOpen={sideBarOpen}
            onMenuCLick={() => navigate(RoutePath.app.admin.path)} />
          )}
          <ListItemButton
            sx={selectedIndex === 'admin' ? sx.selectedMenuItem : sx.menuItem}
            onClick={() => {
              toggleMenu();
            }}
          >
            {sideBarOpen ? (
              <SvgIcon sx={{ marginRight: '0.5rem' }}>
                <ArrowLeft />
              </SvgIcon>
            ) : (
              <Tooltip title="Expand">
                <SvgIcon sx={{ marginRight: '0.5rem' }}>
                  <ArrowRight />
                </SvgIcon>
              </Tooltip>
            )}
            <ListItemText
              primary="Collapse"
              sx={{ opacity: sideBarOpen ? 1 : 0 }}
              primaryTypographyProps={{ fontSize: '0.85rem' }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
