import React from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';

export default function InputHeader({ title }) {
  return (
    <Grid item xs={12}>
      <Typography variant="h2" paddingTop="1rem">{ title }</Typography>
    </Grid>
  );
}
