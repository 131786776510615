import React, { useEffect } from 'react';
import MultiCategoryMultiSelect from 'common/components/MultiCategoryMultiSelect';
import useKeywordSuggestions from '../hooks/useKeywordSuggestions';

const categories = {
  CODE: {
    All: '',
    'CPT\u00AE': 'CPT',
    'MS-DRG': 'MS-DRG',
    HCPCS: 'HCPCS',
    RC: 'RC',
    'Code-Cat': 'Code Category',
  },
  PROVIDER: {
    All: '',
    TIN: 'TAX-ID',
    NPI: 'NPI',
    Grouping: 'Provider Group',
    Taxonomy: 'NUCC',
  },
};

const label = {
  CODE: 'Code Selection',
  PROVIDER: 'Provider Selection',
};

const infoText = {
  CODE: 'Use Code type to narrow typeahead results',
  PROVIDER: 'Use Provider type to narrow typeahead results',
};

const fieldName = {
  CODE: 'selectedCodes',
  PROVIDER: 'selectedProviders',
};

const autocompId = {
  CODE: 'codeFilter',
  PROVIDER: 'providerFilter',
};

export default function SelectCodesOrProviders(props) {
  const {
    variant,
    stateMsaOption,
    clearCount,
    selectedStateObj,
    selectedMsaObj,
  } = props;

  const {
    suggestionsLoading,
    suggestions,
    setKeyword,
    setStateAndMsaNo,
    setType,
  } = useKeywordSuggestions(variant);

  useEffect(() => {
    setKeyword('');
    if (stateMsaOption === 'state') setStateAndMsaNo(selectedStateObj?.code, null);
    else setStateAndMsaNo(null, selectedMsaObj?.msaNo);
  }, [stateMsaOption, selectedStateObj, selectedMsaObj]);

  useEffect(() => {
    if (clearCount) {
      setKeyword('');
    }
  }, [clearCount]);

  return (
    <MultiCategoryMultiSelect
      categories={Object.keys(categories[variant])}
      label={label[variant]}
      infoText={infoText[variant]}
      fieldName={fieldName[variant]}
      autocompId={autocompId[variant]}
      autocompProps={{
        filterOptions: (options) => options,
      }}
      handleCategoryChange={(category) => setType(categories[variant][category])}
      options={suggestions}
      optionsLoading={suggestionsLoading}
      returnOptionText={(option) => (
        <span>
          <b>{option.type === 'NUCC' ? 'Provider Taxonomy' : option.type}</b>
          {` - ${option.value}`}
        </span>
      )}
      onInputChange={(e) => setKeyword(e.target.value)}
      returnChipLabel={(option) => {
        let chipLabel = option.id;
        switch (option.type) {
          case categories.CODE['Code-Cat']:
          case categories.PROVIDER.Taxonomy:
          case categories.PROVIDER.Grouping:
            chipLabel = option.value;
            break;
          case categories.PROVIDER.TIN:
            chipLabel = `TIN ${option.searchValue}`;
            break;
          default:
            chipLabel = `${option.type} ${option.searchValue}`;
            break;
        }
        return chipLabel;
      }}
      forceCategoryReset={clearCount}
    />
  );
}
