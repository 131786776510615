import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'app/router/RoutePath';
import { closeLogoutDialog } from 'app/state/reducer';
import store from 'app/store';

export default function LogoutConfirmDialog({ open }) {
  const navigate = useNavigate();

  const logout = () => {
    store.dispatch(closeLogoutDialog);

    navigate(RoutePath.clientPortal.path);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5">
          <WarningIcon sx={{ transform: 'translate(-1px, 5px)' }} />
          You have been signed out.
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container flexDirection="row" alignItems="flex-end" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              You will now be returned to the Client Portal.
              <br />
              Click the &ldquo;PlanOptix&rdquo; button in the Client Portal to sign back in.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            logout();
          }}
        >
          Return to Client Portal
        </Button>
      </DialogActions>
    </Dialog>
  );
}
