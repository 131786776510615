import { createSlice } from '@reduxjs/toolkit';
import { getMenuCounts } from './service';

export const countSlice = createSlice({
  name: 'count',
  initialState: {
    searchCount: null,
    completedEvaluationCount: null,
    weightsCount: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMenuCounts.fulfilled, (state, action) => {
      if (action.payload.exportSearchCount >= 0) {
        state.searchCount = action.payload.exportSearchCount;
      }
      if (action.payload.evaluationCount >= 0) {
        state.completedEvaluationCount = action.payload.evaluationCount;
      }
      if (action.payload.weightsCount >= 0) {
        state.weightsCount = action.payload.weightsCount;
      }
    });
  },
});

export default countSlice.reducer;
