import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import EvaluationNpiLinkageDetail from './evaluationNpiLinkageDetail';

export default function EvaluationNpiLinkageDetailDialog({ data, open, handleClose, title }) {
  const methods = useForm({ defaultValues: [], mode: 'onBlur' });
  const { reset } = methods;

  useEffect(() => {
    reset([]);
  }, [data, open]);

  return (
    <FormProvider {...methods}>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <EvaluationNpiLinkageDetail />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
