import { createSlice } from '@reduxjs/toolkit';
import { fetchSystemDate } from './service';

const initialState = {
  systemDate: null,
};
export const dateSlice = createSlice({
  name: 'systemDate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemDate.fulfilled, (state, action) => {
        state.systemDate = action.payload;
      });
  },
});

export default dateSlice.reducer;
