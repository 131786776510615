import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';
import moment from 'moment';
import store from 'app/store';
import { getStudiesList } from '../state/service';

export default function StudiesListDialog({ id, handleClose, viewName }) {
  const [studiesList, setStudiesList] = useState([]);
  useEffect(() => {
    store
      .dispatch(getStudiesList({ id, viewName }))
      .unwrap()
      .then((response) => {
        setStudiesList(response);
      });
  }, []);

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Studies List</DialogTitle>
      <Divider />
      <DialogContent>
        <CustomizedMaterialTable
          title=""
          localization={{
            body: {
              emptyDataSourceMessage: (
                <div
                  style={{
                    height: '57px',
                  }}
                />
              ),
            },
          }}
          options={{
            search: false,
            paging: false,
            toolbar: false,
            debounceInterval: 400,
            headerStyle: {
              height: '57px',
            },
          }}
          columns={[
            {
              title: 'Name',
              field: 'evaluationName',
              render: (rowData) => (
                <Typography>{rowData.evaluationName}</Typography>
              ),
              cellStyle: { width: '70%', overflowWrap: 'anywhere' },
            },
            {
              title: 'Created Date',
              field: 'evaluationCreatedAt',
              render: (rowData) => (
                <Typography>
                  {moment(rowData.evaluationCreatedAt).format('MM/DD/YYYY')}
                </Typography>
              ),
              cellStyle: { width: '30%' },
            },
          ]}
          data={studiesList}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
