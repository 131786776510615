import React from 'react';
import { Box, Typography } from '@mui/material';
import { FORMAT_PERCENTAGE } from 'common/util/commonUtil';
import { useSelector } from 'react-redux';

export default function EvaluationResultDetailedComparsion({ mainNwCode, targetNwCode }) {
  const networkMap = useSelector((state) => state.provider.networkMap);
  const billingClassRatio = useSelector((state) => state.evaluation.billingClassRatio);

  return (
    <Box>
      <Typography variant="h5" color="black">
        Detailed Comparison
      </Typography>
      <Typography variant="body1">
        Detailed Comparison between
        {' '}
        <strong>
          {networkMap[mainNwCode]}
        </strong>
        {' '}
        and
        {' '}
        <strong>
          {networkMap[targetNwCode]}
        </strong>
      </Typography>
      <Typography mb={2}>
        Data composition is
        {' '}
        {FORMAT_PERCENTAGE(billingClassRatio.insRatio)}
        {' '}
        institutional data and
        {' '}
        {FORMAT_PERCENTAGE(billingClassRatio.profRatio)}
        {' '}
        professional data.
      </Typography>
    </Box>
  );
}
