import React from 'react';
import { Typography } from '@mui/material';

export default function SupportCardContent() {
  return (
    <Typography>
      <Typography>
        Select Create Service Case (below),
        and then select Service Type of “All Other”,
        then select Service “PlanOptix”.
      </Typography>
      <Typography paddingTop="0.5rem">Or to speak with Customer Service, call 866-263-7427.</Typography>
    </Typography>
  );
}
