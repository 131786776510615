/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const checkWeightsLinkage = createAsyncThunk(
  'checkWeightsLinkage',
  async (id) => {
    const response = await axios.post(
      `${MAIN_HOST}/weights/check-linkage/${id}`,
    );
    return response.data;
  },
);
export const deleteWeightsById = createAsyncThunk(
  'deleteWeightsById',
  async (id) => {
    const response = await axios.delete(`${MAIN_HOST}/weights/delete/${id}`);
    return response.data;
  },
);

export const toggleWeightSetPrivacy = createAsyncThunk('toggleWeightSetPrivacy', async (id) => {
  const response = await axios.post(`${MAIN_HOST}/weights/toggle-privacy/${id}`);
  return response.data;
});
