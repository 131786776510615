import { createSlice } from '@reduxjs/toolkit';
import * as API from './service';

const detail = {
  first: [],
};

const convertInpatientResult = (result, result2, result3, result4) => {
  const reValue = [];
  reValue.push({
    category: 'Surgery',
    weight: 12,
    firstValue: result.surgicalRatio,
    secondValue: result2 ? result2.surgicalRatio : null,
    thirdValue: result3 ? result3.surgicalRatio : null,
    fourthValue: result4 ? result4.surgicalRatio : null,
    detail,
  });
  return reValue;
};
const convertInpatientResults = (result) => {
  if (result && Array.isArray(result)) {
    const size = result.length;

    if (size === 1) {
      return convertInpatientResult(result[0]);
    } if (size === 2) {
      return convertInpatientResult(result[0], result[1]);
    } if (size === 3) {
      return convertInpatientResult(result[0], result[1], result[2]);
    } if (size === 4) {
      return convertInpatientResult(result[0], result[1], result[2], result[3]);
    }
  }
  return [];
};

const convertProfessionalResult = (result, result2, result3, result4) => {
  const reValue = [];

  reValue.push({
    category: 'Administration Of Drugs',
    weight: 45,
    firstValue: result.administrationOfDrugsRatio,
    secondValue: result2 ? result2.administrationOfDrugsRatio : null,
    thirdValue: result3 ? result3.administrationOfDrugsRatio : null,
    fourthValue: result4 ? result4.administrationOfDrugsRatio : null,
  });

  reValue.push({
    category: 'Office Visits',
    weight: 45,
    firstValue: result.officeVisitsRatio,
    secondValue: result2 ? result2.officeVisitsRatio : null,
    thirdValue: result3 ? result3.officeVisitsRatio : null,
    fourthValue: result4 ? result4.officeVisitsRatio : null,
  });

  reValue.push({
    category: 'Misc.',
    weight: 45,
    firstValue: result.otherRatio,
    secondValue: result2 ? result2.otherRatio : null,
    thirdValue: result3 ? result3.otherRatio : null,
    fourthValue: result4 ? result4.otherRatio : null,
  });

  reValue.push({
    category: 'Pathology Lab',
    weight: 45,
    firstValue: result.pathologyLabRatio,
    secondValue: result2 ? result2.pathologyLabRatio : null,
    thirdValue: result3 ? result3.pathologyLabRatio : null,
    fourthValue: result4 ? result4.pathologyLabRatio : null,
  });

  reValue.push({
    category: 'Preventive Visits',
    weight: 45,
    firstValue: result.preventiveVisitsRatio,
    secondValue: result2 ? result2.preventiveVisitsRatio : null,
    thirdValue: result3 ? result3.preventiveVisitsRatio : null,
    fourthValue: result4 ? result4.preventiveVisitsRatio : null,
  });
  reValue.push({
    category: 'Radiology',
    weight: 45,
    firstValue: result.radiologyRatio,
    secondValue: result2 ? result2.radiologyRatio : null,
    thirdValue: result3 ? result3.radiologyRatio : null,
    fourthValue: result4 ? result4.radiologyRatio : null,
  });
  reValue.push({
    category: 'Surgery',
    weight: 45,
    firstValue: result.surgeryRatio,
    secondValue: result2 ? result2.surgeryRatio : null,
    thirdValue: result3 ? result3.surgeryRatio : null,
    fourthValue: result4 ? result4.surgeryRatio : null,
  });
  return reValue;
};

const convertOutpatientResult = (result, result2, result3, result4) => {
  const reValue = [];

  reValue.push({
    category: 'ER',
    weight: 45,
    firstValue: result.erRatio,
    secondValue: result2 ? result2.erRatio : null,
    thirdValue: result3 ? result3.erRatio : null,
    fourthValue: result4 ? result4.erRatio : null,
  });

  reValue.push({
    category: 'Pathology Lab',
    weight: 45,
    firstValue: result.pathologyLabRatio,
    secondValue: result2 ? result2.pathologyLabRatio : null,
    thirdValue: result3 ? result3.pathologyLabRatio : null,
    fourthValue: result4 ? result4.pathologyLabRatio : null,
  });

  reValue.push({
    category: 'Misc.',
    weight: 45,
    firstValue: result.miscRatio,
    secondValue: result2 ? result2.miscRatio : null,
    thirdValue: result3 ? result3.miscRatio : null,
    fourthValue: result4 ? result4.miscRatio : null,
  });

  reValue.push({
    category: 'Radiology',
    weight: 45,
    firstValue: result.radiologyRatio,
    secondValue: result2 ? result2.radiologyRatio : null,
    thirdValue: result3 ? result3.radiologyRatio : null,
    fourthValue: result4 ? result4.radiologyRatio : null,
  });
  reValue.push({
    category: 'Surgery',
    weight: 45,
    firstValue: result.surgeryRatio,
    secondValue: result2 ? result2.surgeryRatio : null,
    thirdValue: result3 ? result3.surgeryRatio : null,
    fourthValue: result4 ? result4.surgeryRatio : null,
  });
  return reValue;
};

const convertOutpatientResults = (result) => {
  if (result && Array.isArray(result)) {
    const size = result.length;

    if (size === 1) {
      return convertOutpatientResult(result[0]);
    } if (size === 2) {
      return convertOutpatientResult(result[0], result[1]);
    } if (size === 3) {
      return convertOutpatientResult(result[0], result[1], result[2]);
    } if (size === 4) {
      return convertOutpatientResult(result[0], result[1], result[2], result[3]);
    }
  }
  return [];
};

const convertProfessionalResults = (result) => {
  if (result && Array.isArray(result)) {
    const size = result.length;

    if (size === 1) {
      return convertProfessionalResult(result[0]);
    } if (size === 2) {
      return convertProfessionalResult(result[0], result[1]);
    } if (size === 3) {
      return convertProfessionalResult(result[0], result[1], result[2]);
    } if (size === 4) {
      return convertProfessionalResult(result[0], result[1], result[2], result[3]);
    }
  }
  return [];
};

const covnerOverAllResult = (result, label) => {
  if (result != null && Array.isArray(result)) {
    const inLength = result.length;

    return {
      name: label,
      firstValue: result[0].overallRatio,
      secondValue: inLength > 1 ? result[1].overallRatio : null,
      thirdValue: inLength > 2 ? result[2].overallRatio : null,
      fourthValue: inLength > 3 ? result[3].overallRatio : null,
    };
  }
  return {
    name: label,
  };
};

const convertOverallResults = (
  inPatientResult,
  outPatientResult,
  professionalResult,
) => {
  const result = [];

  result.push(covnerOverAllResult(inPatientResult, 'Inpatient'));
  result.push(covnerOverAllResult(outPatientResult, 'Outpatient'));
  result.push(covnerOverAllResult(professionalResult, 'Professional'));

  return result;
};

export const resultSlice = createSlice({
  name: 'result',
  initialState: {
    showFirstColumn: false,
    showSecondColumn: false,
    showThirdColumn: false,
    showFourthColumn: false,
    firstColumnHeader: 'column1',
    secondColumnHeader: 'column2',
    thirdColumnHeader: 'column3',
    fourthColumnHeader: 'column4',
    inpatientResult: [],
    outpatientResult: [],
    professionalResult: [],
    overallResult: [],
    compareWith: '',
    showResult: false,
  },
  reducers: {
    reset(state) {
      state.showFirstColumn = false;
      state.showSecondColumn = false;
      state.showThirdColumn = false;
      state.showFourthColumn = false;
      state.firstColumnHeader = 'column1';
      state.secondColumnHeader = 'column2';
      state.thirdColumnHeader = 'column3';
      state.fourthColumnHeader = 'column4';
      state.inpatientResult = [];
      state.outpatientResult = [];
      state.professionalResult = [];
      state.overallResult = [];
      state.compareWith = '';
      state.showResult = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(API.generateResult.fulfilled, (state, action) => {
      state.inpatientResult = convertInpatientResults(action.payload.inpatientResult);
      state.outpatientResult = convertOutpatientResults(action.payload.outpatientResult);
      state.professionalResult = convertProfessionalResults(action.payload.professionalResult);
      state.overallResult = convertOverallResults(
        action.payload.inpatientResult,
        action.payload.outpatientResult,

        action.payload.professionalResult,
      );

      state.showResult = true;
      if (action.payload && action.payload.inpatientResult
        && Array.isArray(action.payload.inpatientResult)) {
        state.showFirstColumn = false;
        state.showSecondColumn = false;
        state.showThirdColumn = false;
        const { inpatientResult } = action.payload;

        if (inpatientResult.length > 0) {
          state.compareWith = inpatientResult[0].name;
        }
        if (inpatientResult.length === 1) {
          state.showFirstColumn = true;
          state.firstColumnHeader = inpatientResult[0].name;
          state.inpatientResult = convertInpatientResult(inpatientResult[0]);
        } else if (inpatientResult.length === 2) {
          state.showFirstColumn = true;
          state.showSecondColumn = true;
          state.firstColumnHeader = inpatientResult[0].name;
          state.secondColumnHeader = inpatientResult[1].name;
        } else if (inpatientResult.length === 3) {
          state.showFirstColumn = true;
          state.showSecondColumn = true;
          state.showThirdColumn = true;
          state.firstColumnHeader = inpatientResult[0].name;
          state.secondColumnHeader = inpatientResult[1].name;
          state.thirdColumnHeader = inpatientResult[2].name;
        } else if (inpatientResult.length === 4) {
          state.showFirstColumn = true;
          state.showSecondColumn = true;
          state.showThirdColumn = true;
          state.firstColumnHeader = inpatientResult[0].name;
          state.secondColumnHeader = inpatientResult[1].name;
          state.thirdColumnHeader = inpatientResult[2].name;
          state.fourthColumnHeader = inpatientResult[3].name;
        }
      }
    });
  },
});

export const { reset } = resultSlice.actions;
export default resultSlice.reducer;
