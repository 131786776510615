import React from 'react';
import { toPng } from 'html-to-image';
import store from 'app/store';
import { setInlinePageError } from 'app/state/reducer';
import Footer from 'features/footer/footer';
import { Provider } from 'react-redux';
import bstLogo from 'asset/bst-logo.png';
import { Paper } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { trackVisit } from 'features/common/state/service';

const handleExport = (compRef, filename, backgroundColor, exportType) => {
  const filter = (node) => {
    const exclusionClasses = ['HtmlToImageIgnore'];
    return !exclusionClasses.some((classname) => node.classList?.contains(classname));
  };

  if (compRef.current) {
    const headerDiv = document.createElement('div');
    const cloneRef = compRef.current.cloneNode(true);
    cloneRef.style.paddingLeft = '20px';
    cloneRef.style.paddingRight = '20px';
    const footerDiv = document.createElement('footer');
    const wholeImageDiv = document.createElement('div');
    wholeImageDiv.appendChild(headerDiv);
    wholeImageDiv.appendChild(cloneRef);
    wholeImageDiv.appendChild(footerDiv);

    const headerRoot = createRoot(headerDiv);
    headerRoot.render(<Paper backgroundColor="white"><img src={bstLogo} alt="BST" className="app-logo" height="40" /></Paper>);

    const footerRoot = createRoot(footerDiv);
    footerRoot.render(<Provider store={store}><Footer /></Provider>);
    document.body.appendChild(wholeImageDiv);
    const height = cloneRef.clientHeight + 108;

    toPng(wholeImageDiv, { cacheBust: true, backgroundColor, height, filter })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${filename}.png`;
        link.href = dataUrl;
        link.click();
        store.dispatch(trackVisit(exportType));
      })
      .catch(() => {
        store.dispatch(
          setInlinePageError('Unable to download'),
        );
      }).finally(() => {
        document.body.removeChild(wholeImageDiv);
      });
  }
};

export default handleExport;
