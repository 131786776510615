import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const generateResult = createAsyncThunk(
  'mrf/search',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/mrf/search`, data);
    return response.data;
  },
);

export default generateResult;
