import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import './details.css';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { FORMAT_PERCENTAGE } from 'common/util/commonUtil';
import CustomRating from 'common/components/CustomRating';
import CustomInfoIcon from 'common/components/CustomInfoIcon';
import * as Constants from 'common/Constants';
import NetworkSummaryPaper from './NetworkSummaryPaper';
import NetworkSummaryTitle from './NetworkSummaryTitle';

export const summaryBoxStyles = {
  width: '270px',
  minHeight: '168px',
  paddingLeft: '35px',
  paddingRight: '35px',
  paddingTop: '10px',
  paddingBottom: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

export const summaryPercentStyles = {
  height: '40px',
  display: 'flex',
  fontSize: '1.25rem',
  fontWeight: 'bold',
  alignItems: 'center',
};

export const comparisonDescriptionStyles = {
  fontSize: '12px !important',
  lineHeight: '12px !important',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  WebkitBoxPack: 'end',
  MozBoxPack: 'end',
};

const useStyles = makeStyles(() => ({
  resultBox: summaryBoxStyles,
  selectedBox: {
    borderRadius: '20px',
    backgroundColor: Constants.PRIMARY,
    '& > p,span': {
      color: 'white',
    },
  },
  evaluationChecked: {
    position: 'absolute',
    marginLeft: '5px',
    marginTop: '5px',
    color: 'white',
  },
  evaluationResultDescription: comparisonDescriptionStyles,
}));

export default function EvaluationResultDetailMainCard(props) {
  const { selected, data, handleClick, isMarketStudy } = props;

  const styles = useStyles();
  // const cardRef = useRef(null);
  const evaluation = useSelector((state) => state.evaluation.evaluation);
  const networkMap = useSelector((state) => state.provider.networkMap);

  const generateTooltipText = (cardInfo) => {
    const mainNetwork = networkMap[evaluation.networkCode];
    if (cardInfo && cardInfo.ratio > 0) {
      return `Higher rate than ${mainNetwork}`;
    }
    if (cardInfo && cardInfo.ratio < 0) {
      return `Lower rate than ${mainNetwork}`;
    }
    return `No difference with ${mainNetwork}`;
  };

  return (
    <NetworkSummaryPaper clickHandler={() => handleClick()} style={{ cursor: 'pointer' }}>
      {selected === true
      && (
      <CheckCircleOutlinedIcon
        className={styles.evaluationChecked} />
      )}
      <Box
        className={
        `${styles.resultBox} ${selected === true ? styles.selectedBox : ''}`
      }
    >
        <NetworkSummaryTitle title={networkMap[data.networkCode] ?? 'Unknown'} />
        <Typography
          sx={summaryPercentStyles}>
          {FORMAT_PERCENTAGE(data.ratio, 0)}
        </Typography>
        <Typography
          className={styles.evaluationResultDescription}
          variant="resultCard">
          {generateTooltipText(data)}
        </Typography>
        {isMarketStudy === true && (
          <Typography style={{ position: 'inherit', bottom: '.5rem', fontSize: '.8rem', display: 'flex', marginTop: '1px' }}>
            <Tooltip title="Data Usability Rating">
              <Typography>
                <CustomRating ratingProps={{ name: 'networkRating', readOnly: true, value: data.networkRating, marginTop: '1px', outlineColor: selected === true ? 'white' : Constants.COLOR_OUTLINE_GREY }} />
              </Typography>
            </Tooltip>
            <CustomInfoIcon title="A measure of procedure codes with sufficient rate data" svgProps={{ height: '20px' }} iconBtnStyles={{ marginTop: '-5px' }} iconBorderColor={selected === true ? 'white' : 'secondary'} />
          </Typography>
        )}
        <Typography
          style={{
            borderRadius: '10px',
            fontSize: '.9rem',
            fontWeight: 500,
            padding: '.3rem 1rem',
            position: isMarketStudy === true ? 'inherit' : 'absolute',
            bottom: '.5rem',
            color: selected ? '#1976D2' : 'white',
            backgroundColor: selected ? 'white' : '#1976D2',
          }}
        >
          {selected ? 'Details Below' : 'View Details'}
        </Typography>
      </Box>
    </NetworkSummaryPaper>
  );
}
