import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Tooltip, Button } from '@mui/material';
import { ReactComponent as CustomFileDownloadIcon } from 'asset/file-download.svg';
import { ReactComponent as CustomCancelIcon } from 'asset/xbox-x.svg';
import { ReactComponent as CustomCircleChevronDownIcon } from 'asset/circle-chevron-down.svg';
import { ReactComponent as CustomCircleChevronRightIcon } from 'asset/circle-chevron-right.svg';
import { usePermissions } from 'features/common/hooks/usePermissions';
import { PERMISSION } from 'common/Constants';

export const UtilityButton = styled(Button)({
  textTransform: 'none !important',
  whiteSpace: 'nowrap',
});

export default function UtilityButtons(props) {
  const {
    advanceControlsVisible,
    toggleAdvanceControls,
    clearSearch,
    exportSearch,
    searchDisabled,
  } = props;
  const { hasPermission } = usePermissions();
  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid item>
        <UtilityButton
          variant="text"
          color="secondary"
          startIcon={
            advanceControlsVisible ? <CustomCircleChevronDownIcon />
              : <CustomCircleChevronRightIcon />
          }
          onClick={toggleAdvanceControls}
        >
          Advanced Search
        </UtilityButton>
      </Grid>
      <Grid item>
        <UtilityButton
          variant="text"
          color="secondary"
          startIcon={<CustomCancelIcon />}
          onClick={clearSearch}
        >
          Clear Search
        </UtilityButton>
        {hasPermission(PERMISSION.DOWNLOAD_SEARCH) && (
          <Tooltip
            title={
              searchDisabled ? 'Must select State/MSA and 1 or more keywords' : 'Click here to export results'
            }
          >
            <span>
              <UtilityButton
                variant="text"
                color="secondary"
                disabled={searchDisabled}
                startIcon={<CustomFileDownloadIcon />}
                onClick={exportSearch}
              >
                Export Search
              </UtilityButton>
            </span>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
}
