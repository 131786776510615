import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'app/router/RoutePath';
import store from 'app/store';
import { resetAccessDeniedFlag } from 'features/evaluation/state/reducer';

export default function AccessDeniedDialog({ open }) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    store.dispatch(resetAccessDeniedFlag());
    const link = RoutePath.app.evaluation.path;
    navigate(link);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5">
          <WarningIcon sx={{ transform: 'translate(-5px, 5px)' }} />
          Access Denied
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container flexDirection="row" alignItems="flex-end" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              You are not authorized to open that Study!
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleNavigate}
          >
          Go to intelligence page
        </Button>
      </DialogActions>
    </Dialog>
  );
}
