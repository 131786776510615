import React from 'react';
import { Navigate } from 'react-router-dom';
import { COOKIE_JWT, SIGN_OUT } from 'common/AppConstant';
import store from 'app/store';
import { updateJwt } from 'features/login/state/reducer';
import RoutePath from './RoutePath';

export default function PrivateRoute({ children }) {
  const foundJwt = localStorage.getItem(COOKIE_JWT);
  if ((!foundJwt || foundJwt === 'null')) {
    if (localStorage.getItem(SIGN_OUT) === 'true') {
      return children;
    }
    return <Navigate to={RoutePath.login.path} replace />;
  }
  store.dispatch(updateJwt(foundJwt));
  return children;
}
