import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'app/router/RoutePath';

export default function PermissionDenied() {
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.auth.permissions);

  useEffect(() => {
    const hasOtherthanIntelligenceRole = permissions?.some((permission) => !permission.includes('INTELLIGENCE'));
    if (!hasOtherthanIntelligenceRole) {
      navigate(RoutePath.app.evaluation.path);
    }
  }, [permissions]);

  return (
    'DENIED'
  );
}
