import { Grid, Typography, Tab, Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import StarIcon from '@mui/icons-material/Star';
import { DARK_GRAY, HOME_PAGE_TITLE_DARK, VIEW_ALL_PLAN, VIEW_PLAN_BY_MSA, VIEW_PLAN_BY_STATE, HOME_ALL_PLANS, HOME_BY_STATE, HOME_BY_MSA } from 'common/Constants';
import React, { useState } from 'react';
import { styled } from '@mui/system';
import DataUsabilityRatingLink from 'common/components/DataUsabilityRatingLink';
import { BoxHeaderStyled, BoxStyled, HomeTabsStyled } from 'features/evaluation/result/TabStyles';
import { trackVisit } from 'features/common/state/service';
import store from 'app/store';
import ViewPlansByMSATable from './ViewPlansByMSATable';
import ViewAllPlansTable from './ViewAllPlansTable';
import ViewPlansByStateTable from './ViewPlansByStateTable';

const SectionPlans = styled('div')(() => ({
  width: '42rem',
  padding: '1.5rem',
  borderRadius: '0.5rem',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
  backgroundColor: 'white',
  height: 'fit-content',
}));

const TopPlanText = styled('h2')(() => ({
  color: HOME_PAGE_TITLE_DARK,
  paddingLeft: '0.75rem',
  fontSize: '1.5rem',
  marginBlockStart: '0rem',
  marginBlockEnd: '0rem',
}));

const useStyles = makeStyles(() => ({
  beta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: DARK_GRAY,
    borderRadius: '0.5rem',
    width: '2.5rem',
    height: '1.5rem',
  },
  boxGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export default function TopPlans() {
  const classNames = useStyles();
  const [selectedTab, setSelectedTab] = useState('viewAllPlans');

  const handleTabClick = (activeTab) => {
    setSelectedTab(activeTab);
    if (activeTab === VIEW_ALL_PLAN) {
      store.dispatch(trackVisit(HOME_ALL_PLANS));
    } else if (activeTab === VIEW_PLAN_BY_STATE) {
      store.dispatch(trackVisit(HOME_BY_STATE));
    } else if (activeTab === VIEW_PLAN_BY_MSA) {
      store.dispatch(trackVisit(HOME_BY_MSA));
    }
  };

  const renderTable = () => {
    if (selectedTab === VIEW_PLAN_BY_MSA) {
      return <ViewPlansByMSATable />;
    } if (selectedTab === VIEW_ALL_PLAN) {
      return <ViewAllPlansTable />;
    } if (selectedTab === VIEW_PLAN_BY_STATE) {
      return <ViewPlansByStateTable />;
    }
    return null;
  };

  return (
    <SectionPlans>
      <Grid container>
        <Grid item xs={0.3}>
          <StarIcon sx={{ color: HOME_PAGE_TITLE_DARK, marginTop: '0.25rem' }} />
        </Grid>
        <Grid item xs={3}>
          <TopPlanText id="plans">Plans</TopPlanText>
        </Grid>
        <Grid item xs={8.7} className={classNames.boxGrid}>
          <Grid item className={classNames.beta}>
            <Typography sx={{ fontSize: '14px' }}>Beta</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography paddingTop="0.5rem" id="top-plan-content">
            These are our current plans. You can view the best plans for Intelligence
            studies as determined by MultiPlan&apos;s new Data Usability
            Rating system
            <b> by selecting the State or MSA tab.</b>
            <br />
            <br />
            The Data Usability Ratings are available at the state and MSA level
            and identify which plans publish rates for the top procedure codes
            needed to generate meaningful results in Intelligence Studies.&nbsp;
            <DataUsabilityRatingLink />
            <br />
            <br />
            Note: Plans with a Data Usability Rating of zero stars have some MRF
            rate data but not enough to reach the 0.5 star rating.
          </Typography>
        </Grid>
      </Grid>
      <Grid container paddingTop="0.875rem">
        <Grid item xs={12}>
          <BoxHeaderStyled display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              justifyContent="space-between"
            >
              <HomeTabsStyled
                value={selectedTab}
                textColor="secondary"
              >
                <Tab label="View All Plans" value={VIEW_ALL_PLAN} onClick={() => handleTabClick(VIEW_ALL_PLAN)} />
                <Tab label="View Plans and Data Usability Ratings by State" value={VIEW_PLAN_BY_STATE} onClick={() => handleTabClick(VIEW_PLAN_BY_STATE)} />
                <Tab label="View Plans and Data Usability Ratings by MSA" value={VIEW_PLAN_BY_MSA} onClick={() => handleTabClick(VIEW_PLAN_BY_MSA)} />
              </HomeTabsStyled>
              <BoxStyled
                alignSelf="end"
                display="flex"
                flexDirection="row" />
            </Box>
            <Divider />
          </BoxHeaderStyled>
        </Grid>
      </Grid>
      <Grid container paddingTop="0.875rem">
        {renderTable()}
      </Grid>
    </SectionPlans>
  );
}
