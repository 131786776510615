import { Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import React from 'react';

export default function BasicCard({ content }) {
  const { header, body1, actions } = content;
  return (
    <Card sx={{ padding: 0, height: '100%' }}>
      <CardHeader title={header} sx={{ padding: '12px' }} titleTypographyProps={{ variant: 'h2' }} />
      <CardContent sx={{ paddingTop: '0px!important', paddingBottom: '0px!important' }}>
        {body1
          && (
            <Typography variant="body2">
              {body1}
            </Typography>
          )}
      </CardContent>
      {actions
        && (
          <CardActions disableSpacing sx={{ flexDirection: 'row-reverse' }}>
            {actions}
          </CardActions>
        )}
    </Card>
  );
}
