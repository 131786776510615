/* eslint-disable no-use-before-define */
import React from 'react';
import { TextField, Checkbox, Chip } from '@mui/material';
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SHOW_ALL_RESULT } from 'common/Constants';

export default function MultiSelect({
  items,
  label,
  selectedOptions,
  selectAllLabel,
  limitTags,
  allLabel,
  onSelectAll,
  onToggleOption,
  onClearOptions,
}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const allSelected = items.length === selectedOptions.length;
  const handleToggleSelectAll = () => onSelectAll(!allSelected);

  const handleChange = (event, selectedValues, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedValues.find((option) => option.value === SHOW_ALL_RESULT)) {
        handleToggleSelectAll();
      } else {
        onToggleOption(selectedValues);
      }
    } else if (reason === 'clear') {
      onClearOptions();
    }
  };

  const filter = createFilterOptions();
  return (
    <Autocomplete
      size="small"
      multiple
      onChange={(_e, selectedMsas, reason) => handleChange(_e, selectedMsas, reason)}
      defaultValue={[{ label: selectAllLabel, value: SHOW_ALL_RESULT }]}
      fullWidth
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(opt, val) => opt.label === val.label}
      options={items}
      value={selectedOptions}
      limitTags={limitTags}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        const selectAllProps = option.value === SHOW_ALL_RESULT
          ? { checked: allSelected }
          : {};
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              {...selectAllProps}
            />
            {option.label}
          </li>
        );
      }}
      renderInput={(inProps) => (
        <TextField
          {...inProps}
          InputLabelProps={{
            shrink: true,
          }}
          label={label}
        />
      )}
      renderTags={(selectedValues, getTagProps) => {
        const tag = allSelected
          ? <Chip label={allLabel} />
          : selectedValues.map((o, index) => (
            <Chip variant="contained" label={o.value} {...getTagProps({ index })} />
          ));
        return tag;
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [{ label: selectAllLabel, value: SHOW_ALL_RESULT }, ...filtered];
      }}
    />
  );
}
