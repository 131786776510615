import React from 'react';
import { Grid, TextField, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FormAutocompleteTextField from 'common/components/FormAutocompleteTextField';

const negotiatedTypes = ['negotiated', 'percentage', 'derived', 'fee schedule'];
const billingClasses = ['Institutional', 'Professional'];
// From Siping's updated Search engine, -1 means lower outliers,
// 1 means higher outliers and 0 means not an outlier.
const outlierOptions = [
  { label: 'Include', value: '-1,0,1' },
  { label: 'Exclude', value: '0' },
  { label: 'Outliers Only', value: '-1,1' },
];

const negotiationArrangements = ['bundle', 'capitation', 'ffs']; // sorted

export default function AdvanceControls({ onPlanTypeChange }) {
  const { control } = useFormContext();
  const planTypes = useSelector((state) => state.provider.planTypes);

  /* need to be discussed
  const [isValidRate, setIsValidRate] = useState(true);
  const [validationMessage, setValidationMessage] = useState('');

  const validateMRFRate = () => {
    const mrfMinRate = getValues('mrfMinRate');
    const mrfMaxRate = getValues('mrfMaxRate');
    if (mrfMaxRate !== '' && mrfMinRate !== '') {
      if (mrfMaxRate < mrfMinRate) {
        setIsValidRate(false);
        setValidationMessage('MRF Min Rate should not be greater than Max Rate. ');
      } else {
        setIsValidRate(true);
        setValidationMessage('');
      }
    } else {
      setIsValidRate(true);
      setValidationMessage('');
    }
  }; */

  return (
    <Grid item container direction="row" spacing={1.5} alignItems="flex-end">
      <Grid item xs={5} md={2.5} lg={2} xl={1.5}>
        <FormAutocompleteTextField
          fieldName="planType"
          onValueChange={() => onPlanTypeChange()}
          autocompleteProps={{
            id: 'planTypeDropdown',
            options: planTypes,
          }}
          textFieldProps={{
            label: 'Plan Type',
          }}
        />
      </Grid>
      <Grid item xs={7} md={5} lg={3} xl={2}>
        <FormAutocompleteTextField
          fieldName="billingClass"
          autocompleteProps={{
            id: 'billingClassDropdown',
            options: billingClasses,
          }}
          textFieldProps={{
            label: 'Billing Class',
          }}
        />
      </Grid>
      <Grid item xs={7} md={3} lg={3} xl={1.8}>
        <FormAutocompleteTextField
          fieldName="negotiationArrangement"
          autocompleteProps={{
            id: 'negotiationArrangementDropdown',
            options: negotiationArrangements,
          }}
          textFieldProps={{
            label: 'Negotiation Arrangement',
          }}
        />
      </Grid>
      <Grid item xs={4} md={3} lg={3} xl={2}>
        <FormAutocompleteTextField
          fieldName="negotiatedType"
          autocompleteProps={{
            id: 'negotiatedTypeDropdown',
            options: negotiatedTypes,
          }}
          textFieldProps={{
            label: 'Negotiated Type',
          }}
        />
      </Grid>
      <Grid item xs={8} md={8} lg={4} xl={3}>
        <Stack spacing={0.1}>
          <Typography variant="subtitle2" component="div" pl="1rem">
            MRF Rate Range
          </Typography>
          <Grid container spacing={1} columns={12}>
            <Grid item xs={5.7}>
              <Controller
                name="mrfMinRate"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="mrfMinRateInput"
                    label="Min Rate"
                    type="number"
                    {...field}
                    inputProps={{ maxLength: 10 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5.7}>
              <Controller
                name="mrfMaxRate"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="mrfMaxRateInput"
                    label="Max Rate"
                    type="number"
                    {...field}
                    inputProps={{ maxLength: 10 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={5} md={2.5} lg={2} xl={1.5}>
        <FormAutocompleteTextField
          fieldName="outlier"
          autocompleteProps={{
            id: 'outlierDropdown',
            options: outlierOptions,
            isOptionEqualToValue: (option, value) => option.label === value.label,
          }}
          textFieldProps={{
            label: 'Outlier',
          }}
        />
      </Grid>
    </Grid>
  );
}
