import {
  Alert,
  Button,
  Grid,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

export default function EvaluationAction(props) {
  const { onClick, isDisabled, sizeXS, isPopUpOpen } = props;
  const errorMessage = useSelector((state) => state.app.inlinePageError);
  return (
    <>
      {
        (errorMessage
        && !isPopUpOpen) && <Grid item xs={12}><Grid container><Alert severity="error">{errorMessage}</Alert></Grid></Grid>
      }
      <Grid item xs={12}>
        <Grid container xs={sizeXS || 6} justifyContent="flex-end" paddingTop={2} paddingRight={4}>
          <Button
            variant="contained"
            onClick={onClick}
            disabled={isDisabled}
          >
            Create Study
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
