import { createSlice } from '@reduxjs/toolkit';
import * as API from './service';

export const downloadSlice = createSlice({
  name: 'Download',
  initialState: {
    documents: [],
    refreshFlag: true,
  },
  reducers: {
    resetDownload(state) {
      state.documents = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(API.regenerateFile.fulfilled, (state) => {
      state.refreshFlag = !state.refreshFlag;
    });
    builder.addCase(API.deleteDownloadById.fulfilled, (state) => {
      state.refreshFlag = !state.refreshFlag;
    });
  },
});

export const {
  resetDownload,
} = downloadSlice.actions;
export default downloadSlice.reducer;
