import { Divider } from '@mui/material';
import React from 'react';

function CustomDivider() {
  return (
    <Divider sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }} />
  );
}

export default CustomDivider;
