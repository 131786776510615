import { styled, createTheme } from '@mui/material/styles';
import { HOME_PAGE_TITLE_DARK } from 'common/Constants';

export const HeaderRow = styled('div')(() => ({
  fontSize: '1rem',
  color: HOME_PAGE_TITLE_DARK,
  fontWeight: 600,
}));

export const HeaderRowRightAlign = styled('div')(() => ({
  fontSize: '1rem',
  color: HOME_PAGE_TITLE_DARK,
  fontWeight: 600,
  textAlign: 'right',
}));

export const CustomHomePageTheme = (customAppTheme) => createTheme({
  ...customAppTheme,
  palette: {
    ...customAppTheme.palette,
    sortIcon: {
      main: '#002E74',
    },
  },
});

export const CustomNetworkResultsHeaderTheme = (customAppTheme) => createTheme({
  ...customAppTheme,
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          '& tr th:last-child': {
            textAlign: 'right !important',
          },
        },
      },
    },
  },
});
