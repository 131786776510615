import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

const NwSummaryStyledPaper = styled(Paper)({ // styled material Paper for networks summary
  borderRadius: '20px',
  marginRight: '2rem',
  marginBottom: '1rem',
  width: '270px',
  position: 'relative',
  minHeight: '168px',
});

export default function NetworkSummaryPaper({ clickHandler, children, style }) {
  return (
    <NwSummaryStyledPaper
      elevation={2}
      onClick={clickHandler}
      style={style} // to apply alternate styles
    >
      {children}
    </NwSummaryStyledPaper>
  );
}
