import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import store from 'app/store';
import { HOME_PAGE_TITLE_DARK } from 'common/Constants';
import { resetEvaluation } from 'features/evaluation/state/reducer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { trackVisit } from 'features/common/state/service';
import * as Constants from 'common/Constants';

const NavigateCardTitle = styled('h2')(() => ({
  color: HOME_PAGE_TITLE_DARK,
  fontSize: '1.25rem',
  marginBlockStart: '0rem',
  marginBlockEnd: '0rem',
}));

const CustomCard = styled('div')(() => ({
  width: '24rem',
  padding: '1.5rem',
  borderRadius: '0.5rem',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
  backgroundColor: 'white',
}));

const CardTitle = styled('div')(() => ({
  fontSize: '1.25rem',
  alignItems: 'center',
  flexDirection: 'row',
}));

const CardContent = styled('div')(() => ({
  marginBottom: '1rem',
}));

export default function NavigationCard({ detail }) {
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    if (detail.key !== 'search') {
      store.dispatch(resetEvaluation());
    }

    if (detail.key === 'provider') {
      store.dispatch(trackVisit(Constants.TRACK_VISIT_TYPE.HOME_PROVIDER_STUDY));
      navigate(link, { state: { source: Constants.TRACK_VISIT_TYPE.HOME_PROVIDER_STUDY } });
    } else if (detail.key.toUpperCase() === Constants.EVALTYPE.MARKET) {
      store.dispatch(trackVisit(Constants.TRACK_VISIT_TYPE.HOME_MARKET_STUDY));
      navigate(link, { state: { source: Constants.TRACK_VISIT_TYPE.HOME_MARKET_STUDY } });
    } else if (detail.key.toUpperCase() === Constants.EVALTYPE.POPULATION) {
      store.dispatch(trackVisit(Constants.TRACK_VISIT_TYPE.HOME_POPULATION_STUDY));
      navigate(link, { state: { source: Constants.TRACK_VISIT_TYPE.HOME_POPULATION_STUDY } });
    } else if (detail.key === Constants.PAGE_NAME.SEARCH) {
      store.dispatch(trackVisit(Constants.TRACK_VISIT_TYPE.HOME_SEARCH));
      navigate(link, { state: { source: Constants.TRACK_VISIT_TYPE.HOME_SEARCH } });
    }
  };

  return (
    <CustomCard>
      <CardTitle>
        <Grid container item>
          <Grid item sx={{ paddingRight: '0.5rem', display: 'flex', alignItems: 'center' }}>
            <detail.icon color={HOME_PAGE_TITLE_DARK} />
          </Grid>
          <Grid item>
            <NavigateCardTitle>{detail.title}</NavigateCardTitle>
          </Grid>
        </Grid>
        <CardContent>
          <Typography>{detail.content}</Typography>
        </CardContent>
        <Grid
          container
          sx={{
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              if (detail.link) {
                handleNavigate(detail.link);
              }
              if (detail.onClickHandler) {
                detail.onClickHandler();
                store.dispatch(trackVisit(Constants.TRACK_VISIT_TYPE.HOME_SERVICE_CASE));
              }
            }}
          >
            {detail.buttonText}
          </Button>
        </Grid>
      </CardTitle>
    </CustomCard>
  );
}
