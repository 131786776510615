import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const getCensusList = createAsyncThunk('census/my-list', async () => {
  const response = await axios.get(`${MAIN_HOST}/census/my-list`);
  return response.data;
});

// not a thunk
export const getCensusStatistics = async (id) => {
  const response = await axios.get(`${MAIN_HOST}/census/statistics/${id}`);
  return response.data;
};

export const getStatesForCensus = async (id) => {
  const response = await axios.get(`${MAIN_HOST}/census/states/${id}`);
  return response.data;
};

export const deleteCensusById = createAsyncThunk(
  'deleteCensusById',
  async (id) => {
    const response = await axios.delete(`${MAIN_HOST}/census/${id}`);
    return response.data;
  },
);
