// hook that can be used to get suggestions(codes or providers) based on characters/words typed.
import { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { DEFAULT_PAGE_SIZE, PROVIDER_PAGE_SIZE } from 'common/Constants';
import { callSuggestionApi } from '../state/service';

export default function useKeywordSuggestions(category) {
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [state, setState] = useState(null);
  const [msaNo, setMsaNo] = useState(null);
  const [type, setType] = useState('');
  const pageSize = (category === 'PROVIDER') ? PROVIDER_PAGE_SIZE : DEFAULT_PAGE_SIZE; // Number of suggestions to be fetched.

  useEffect(() => {
    if (keyword && keyword.length > 1) { // suggestions api expects atleast 2 characters
      debounce(() => {
        setSuggestionsLoading(true);
        callSuggestionApi({
          keyword,
          state,
          msaNo,
          type,
          category,
          pageSize,
        }).then((data) => {
          setSuggestions(data);
        }).finally(() => setSuggestionsLoading(false));
      }, 250)();
    } else setSuggestions([]);
  }, [keyword]);

  useEffect(() => {
    setSuggestions([]);
  }, [type]);

  const setStateAndMsaNo = useCallback((stateCode, msaNumber) => {
    setState(stateCode);
    setMsaNo(msaNumber);
  }, []);

  return { suggestionsLoading, suggestions, setKeyword, setStateAndMsaNo, setType };
}
