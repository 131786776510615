import React, { useEffect, useState } from 'react';
import { TableContainer, TableHead } from '@mui/material';
import useCustomSort from 'features/evaluation/hooks/useCustomSort';
import { EVALTYPE, LIGHTER_GRAY_200, WHITE } from 'common/Constants';
import PopulationMSA from './PopulationMSA';
import { StudyResultsTable,
  TableCellNetworkStyledHeader,
  TableRowStyled,
  TypographyNetworkStyledName } from './PopulationStyles';
import HeaderGroup from './HeaderGroup';
import NetworkResultsHeader from './NetworkResultsHeader';
import MsaResultsHeader from './MsaResultsHeader';
import PopulationMSADropdown from './PopulationMSADropdown';

const headerName = {
  [EVALTYPE.POPULATION]: 'MSA',
  [EVALTYPE.PROVIDER]: 'Code Categories',
  [EVALTYPE.MARKET]: 'Code Categories',
};

export default function PopulationResultDetail({
  networkSummaries = [],
  msaResults = {},
  evalType,
  billingClass,
}) {
  const {
    sortedData,
    handleSortState,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    activeSort,
    setActiveSort,
  } = useCustomSort({ initialData: msaResults, shouldSort: billingClass, customSortFlag: true });

  const msaObjectResults = Object.keys(sortedData);
  const [filteredMsa, setFilteredMsa] = useState(msaObjectResults);
  const [expandBitMap, setExpandBitMap] = useState([]); // object to store expand state of each row

  const resetExpandBitMap = () => {
    const len = Object.keys(msaResults).length;
    setExpandBitMap(Array(len).fill(false));
  };

  const handleExpandBitMapChange = (idx, val) => {
    const newMap = [...expandBitMap];
    newMap[idx] = val;
    setExpandBitMap(newMap);
  };

  useEffect(() => {
    setFilteredMsa(Object.keys(sortedData));
    resetExpandBitMap();
    setOrder('desc');
    setOrderBy('weight');
    setActiveSort(true);
  }, [billingClass]);

  useEffect(() => {
    resetExpandBitMap();
    setFilteredMsa(Object.keys(sortedData));
  }, [sortedData]);

  return (
    <>
      {evalType === EVALTYPE.POPULATION
        && (
          <PopulationMSADropdown
            setFilteredMsa={setFilteredMsa}
            msaObjectResults={msaObjectResults}
            msaResults={msaResults}
          />
        )}
      <TableContainer sx={{
        maxHeight: (window.innerHeight - 400) > 300 ? (window.innerHeight - 400) : 300,
        minHeight: (window.innerHeight - 650),
        marginLeft: '16px',
        marginTop: '5px',
      }}>
        <StudyResultsTable stickyHeader aria-label="collapsible table" sx={{ tableLayout: 'fixed' }}>
          <HeaderGroup />
          <TableHead>
            <TableRowStyled>
              <TableCellNetworkStyledHeader sx={{ backgroundColor: `${WHITE} !important` }}>
                <TypographyNetworkStyledName variant="h6" minHeight="50px">
                  {headerName[evalType]}
                </TypographyNetworkStyledName>
                <MsaResultsHeader
                  evalType={evalType}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleSortState}
                  active={activeSort}
                />
              </TableCellNetworkStyledHeader>
              {networkSummaries.map((item, index) => (
                <TableCellNetworkStyledHeader
                  key={`${item.network.code}`}
                  sx={{
                    paddingLeft: '0px !important',
                    paddingRight: evalType === EVALTYPE.POPULATION ? '16px !important' : '0px !important',
                    backgroundColor: index % 2 === 0 ? `${LIGHTER_GRAY_200} !important` : `${WHITE} !important`,
                  }}>
                  <TypographyNetworkStyledName variant="h6" minHeight="50px">{item.network.label}</TypographyNetworkStyledName>
                  <NetworkResultsHeader evalType={evalType} />
                </TableCellNetworkStyledHeader>
              ))}
            </TableRowStyled>
          </TableHead>
          {msaObjectResults.length ? filteredMsa
            .map((msa, idx) => (
              <PopulationMSA
                key={JSON.parse(msa).name}
                msa={JSON.parse(msa)}
                msaPopulationResult={msaResults[msa]}
                networkSummaries={networkSummaries}
                evalType={evalType}
                billingClass={billingClass}
                handleExpandDetails={(val) => {
                  handleExpandBitMapChange(idx, val);
                }}
                expandDetails={expandBitMap[idx]}
              />
            )) : null
          }
        </StudyResultsTable>
      </TableContainer>
    </>
  );
}
