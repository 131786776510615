import { createSlice } from '@reduxjs/toolkit';
import { NPI_TYPES } from 'common/Constants';
import * as API from './service';

export const defaultSearchParams = {
  state: null,
  msa: null,
  planGroupAlias: [],
  planType: '',
  selectedCodes: [],
  selectedProviders: [],
  billingClass: '',
  negotiatedType: '',
  mrfMinRate: '',
  mrfMaxRate: '',
  outlier: '',
  providerEntityType: NPI_TYPES.find((npiType) => npiType.value === 2),
  negotiationArrangement: '',
};

const defaultPrevState = {
  form: { ...defaultSearchParams },
  tableQuery: null,
};

export const searchSlice = createSlice({
  name: 'Search',
  initialState: {
    data: [],
    isLoading: false,
    suggestion: [],
    suggestionLoading: false,
    suggestionMap: {},
    networks: [],
    filterOptions: {
      cbsa: [],
      city: [],
      billingCode: [],
      billingCodeType: [],
      npi: [],
      taxId: [],
      payer: [],
      planGroupAlias: [],
    },
    pagination: {
      afterBillingCode: '',
      afterBillingCodeType: '',
      afterPlanGroupAlias: '',
      total: 0,
    },
    hasMore: false,
    downloadFileStatus: null,
    stateMsaOption: 'state',
    advanceControlsVisible: false,
    prevVisitState: { ...defaultPrevState },
  },
  reducers: {
    setPrevVisitState(state, action) {
      state.prevVisitState = { ...action.payload };
    },
    clearPrevVisitState(state) {
      state.prevVisitState = { ...defaultPrevState };
    },
    setStateMsaOption(state, action) {
      state.stateMsaOption = action.payload;
    },
    setAdvanceControlsVisible(state, action) {
      state.advanceControlsVisible = action.payload;
    },
    resetTINSuggestion(state) {
      state.suggestionLoading = false;
      state.suggestion = [];
      state.suggestionMap = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(API.searchNetworks.pending, (state) => {
      state.networks = [];
    });
    builder.addCase(API.searchNetworks.fulfilled, (state, action) => {
      state.networks = action.payload;
    });
    builder.addCase(API.search.pending, (state) => {
      state.isLoading = true;
      state.pagination = {
        afterBillingCode: '',
        afterBillingCodeType: '',
        afterPlanGroupAlias: '',
      };
    });
    builder.addCase(API.search.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.rates;
      state.total = action.payload.total;

      if (state.data) {
        const optionsMap = {};
        const filters = [
          'cbsa',
          'city',
          'billingCodeType',
          'npi',
          'taxId',
          'payer',
          'billingCode',
          'planGroupAlias',
        ];
        filters.forEach((name) => {
          optionsMap[name] = action.payload[name].map((x) => ({
            label: x.value,
            code: x.value,
          }));
        });
        state.filterOptions = optionsMap;

        state.pagination.afterBillingCode = action.payload.afterBillingCode;
        state.pagination.afterBillingCodeType = action.payload.afterBillingCodeType;
        state.pagination.afterPlanGroupAlias = action.payload.afterPlanGroupAlias;
        state.hasMore = !(action.payload.afterBillingCode == null);
      } else {
        state.filterOptions([]);
      }
    });
    builder.addCase(API.searchWithFilter.fulfilled, (state, action) => {
      state.data = [...state.data, ...action.payload.rates];
      state.pagination.afterBillingCode = action.payload.afterBillingCode;
      state.pagination.afterBillingCodeType = action.payload.afterBillingCodeType;
      state.pagination.afterPlanGroupAlias = action.payload.afterPlanGroupAlias;
      state.hasMore = !(action.payload.afterBillingCode == null);
      state.total = action.payload.total;
    });
    builder.addCase(API.newSearchWithFilter.fulfilled, (state, action) => {
      state.data = action.payload.rates;
      state.pagination.afterBillingCode = action.payload.afterBillingCode;
      state.pagination.afterBillingCodeType = action.payload.afterBillingCodeType;
      state.pagination.afterPlanGroupAlias = action.payload.afterPlanGroupAlias;
      state.hasMore = !(action.payload.afterBillingCode == null);
      state.total = action.payload.total;
    });
    builder.addCase(API.onKeywordChange, (state) => {
      state.suggestionLoading = true;
    });
    builder.addCase(API.clearSuggestions, (state) => {
      state.suggestionLoading = false;
      state.suggestion = [];
      state.suggestionMap = {};
    });
    builder.addCase(API.getSuggestion.rejected, (state) => {
      state.suggestionLoading = false;
    });
    builder.addCase(API.getSuggestion.fulfilled, (state, action) => {
      state.suggestion = action.payload;
      state.suggestionLoading = false;
      state.suggestionMap = {};
      if (Array.isArray(action.payload)) {
        action.payload.forEach((item) => {
          state.suggestionMap[item.npi] = item;
        });
      }
    });
    builder.addCase(API.searchToDownload.fulfilled, (state, action) => {
      if (action.payload) {
        state.downloadFileStatus = 'success';
      }
    });
    builder.addCase(API.clearStatus, (state) => {
      state.downloadFileStatus = null;
    });
  },
});

export const {
  setPrevVisitState,
  clearPrevVisitState,
  setStateMsaOption,
  setAdvanceControlsVisible,
  resetTINSuggestion } = searchSlice.actions;

export default searchSlice.reducer;
