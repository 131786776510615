import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Tooltip } from '@mui/material';

const NetworkName = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem !important',
  fontWeight: 'bold !important',
  lineHeight: '1.25rem !important',
  height: '41px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  WebkitBoxPack: 'end',
  MozBoxPack: 'end',
  color: theme.palette.primary.dark,
}));

export default function NetworkSummaryTitle({ title }) {
  return (
    <Tooltip title={title} placement="top-end" arrow>
      <NetworkName>{title}</NetworkName>
    </Tooltip>
  );
}
