import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Stack,
  Collapse,
  IconButton,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { FORMAT_PERCENTAGE } from 'common/util/commonUtil';
import DetailRow from './detailRow';

export default function TableRow(
  { showDetails, handleShowDetails, data, selectedTab, totalDetails },
) {
  const networkMap = useSelector((state) => state.provider.networkMap);
  const evaluation = useSelector((state) => state.evaluation.evaluation);

  const [currentDetails, setCurrentDetails] = useState(undefined);

  const handleRowClick = () => {
    const details = totalDetails[selectedTab.toUpperCase()][data.category];
    setCurrentDetails(details);
    handleShowDetails(!showDetails);
  };

  const renderSummary = () => (
    <Grid container onClick={() => handleRowClick()} className="tableRows">
      <Grid item xs={3}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <IconButton>
            {showDetails ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography>{data.category}</Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          height="100%"
        >
          <Box flex={1}>
            <Typography variant="body">{data.score}</Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body">{FORMAT_PERCENTAGE(data.idealPercent)}</Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body">{FORMAT_PERCENTAGE(data.medicarePercent, 0, '')}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={2}>
        <Box display="flex" height={1} alignItems="center" justifyContent="center">
          <Typography variant="body">{FORMAT_PERCENTAGE(data.percentDiff, 0)}</Typography>
        </Box>
      </Grid>
    </Grid>
  );

  const renderDataHeader = (details) => {
    if (Array.isArray(details) && details.length > 0) {
      return (
        <Grid container className="nestedTableheaders">
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={2}>
              <Box display="flex" height={1} alignItems="center" justifyContent="center">
                <Typography variant="h6">{networkMap[data.mainNetworkCode]}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" height={1} alignItems="center" justifyContent="center">
                <Typography variant="h6" align="center">
                  {networkMap[data.targetNetworkCode]}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" height={1} alignItems="center" justifyContent="center">
                <Typography variant="h6">Diff %</Typography>
              </Box>
            </Grid>
          </Grid>
          {evaluation.evalType === 'MARKET' && (
            <Grid container>
              <Grid item xs={6} />
              <Grid item xs={1}>
                <Box display="flex" height={1} alignItems="center" justifyContent="center">
                  <Typography variant="h6">Rate</Typography>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" height={1} alignItems="center" justifyContent="center">
                  <Typography variant="h6">NPI Count</Typography>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" height={1} alignItems="center" justifyContent="center">
                  <Typography variant="h6">Rate</Typography>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" height={1} alignItems="center" justifyContent="center">
                  <Typography variant="h6">NPI Count</Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      );
    }
    return (
      <Grid container className="nestedTableheaders">
        <Grid item xs={12}>
          <Box flex={1} flexDirection="row" flexWrap="nowrap" justifyContent="center">
            <Typography variant="h6" align="center">Insufficient Data</Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Stack>
      {renderSummary()}
      <Collapse in={showDetails}>
        <Stack>
          {renderDataHeader(currentDetails)}
          {Array.isArray(currentDetails) && currentDetails.map((detail) => (
            <DetailRow data={detail} />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
}
