import React, { useEffect } from 'react';
import * as Constants from 'common/Constants';
import { Grid, Link, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSystemDate } from './state/service';

const textStyles = {
  fontSize: '12px',
  fontFamily: 'verdana, Helvetica, sans-serif',
  fontColor: Constants.TEXT_BLACK,
};

const gridStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function Footer() {
  const dispatch = useDispatch();
  const systemDate = useSelector((state) => state.systemDate.systemDate);
  useEffect(() => {
    dispatch(fetchSystemDate());
  }, [dispatch]);

  const extraSpaceWithDot = '\u00A0\u00A0\u00A0•\u00A0\u00A0\u00A0';
  const extraSpace = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
  const copyRight = '\u00A9';
  const year = systemDate ? systemDate[0] : null;
  const amaYear = year - 1;
  return (
    <footer>
      <Grid container style={{ gap: '0.5rem', marginBlock: '1rem' }}>
        <Grid item xs={12} sx={gridStyle}>
          <Typography fontSize="0.75rem">
            All rate information contained herein is based on publicly available data submitted
            by payors, in compliance with federal price transparency law.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={gridStyle}>
          <Typography sx={textStyles} fontSize="0.875rem">
            {`${copyRight}${year} MultiPlan Inc. All Rights Reserved ${extraSpaceWithDot}CPT ${copyRight}
            ${amaYear} American Medical Association.  All Rights Reserved.`}
          </Typography>
        </Grid>
        <Grid item container xs={12} sx={gridStyle} className="HtmlToImageIgnore">
          <Typography component={Link} fontSize="0.75rem">
            <Link href={Constants.PRIVACY_POLICY_LINK} target="_blank" rel="noopener">
              Privacy Policy
            </Link>
          </Typography>
          {extraSpace}
          <Typography component={Link} fontSize="0.75rem">
            <Link href={Constants.TERMS_OF_USE_LINK} target="_blank" rel="noopener">
              Terms of Use
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}
export default Footer;
